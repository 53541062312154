import { Container, Grid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { FaHeart, FaIndianRupeeSign } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { CartCard } from "../../../../components/CartCard";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import { useSelector } from "react-redux";
import {
  get_consumable_product_mem_list,
  get_member_cart_list,
  remove_from_cart,
  update_cart,
} from "../../../../redux/dataSlice";
import store from "../../../../redux/store";
import { NoData } from "../../../../components/NoData";
import { toast } from "react-toastify";
import { ReduceFunction } from "../../../../utils/utils";
import useDebounce from "../../../../utils/Hook/DebounceHook";

const ShopCart = () => {
  const [heart, setHeart] = useState(true);
  const [list, setList] = useState([]);
  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const [loading, setLoading] = useState(false);

  const { mem_cart_loading, mem_cart_list, consumable_product_list } =
    useSelector((state) => state.allData);

  const obj = { sub_cat_id: state?.id, userid: user?.id };

  console.log("list ===", list);

  useEffect(() => {
    store.dispatch(get_member_cart_list(obj));
    store.dispatch(get_consumable_product_mem_list(state?.id));
  }, []);

  useEffect(() => {
    setList(mem_cart_list);
  }, [mem_cart_list]);

  const remove_item = async (id) => {
    store.dispatch(remove_from_cart({ id, userId: user?.id, obj }));
  };

  const update_quantity = async (id, qty, min) => {
    if (qty < min) {
      remove_item(id);
    } else {
      setList((prevList) =>
        prevList.map((item) =>
          item.ID === id ? { ...item, quantity: qty } : item
        )
      );
      debouncedUpdate(id, qty);
    }
  };
  const debouncedUpdate = useDebounce((id, qty) => {
    store.dispatch(
      update_cart({
        id,
        qty,
        obj,
        userId: user?.id,
      })
    );
  }, 800);

  const checkout = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "checkout");
      body.append("user_id", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Lets_shop.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        store.dispatch(get_member_cart_list(obj));
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container maxW={"container.xl"} py={[0, 2]}>
      <BreadCrumName
        current={"Cart"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Let's Shop", to: -2 },
          { name: state.name, to: -1 },
        ]}
      />

      <Container maxW={"container.xl"} px={[0, 0, 2]} py={[0, 0, 2]}>
        <div className="shopping-cart">
          {mem_cart_loading || loading ? (
            <Loader />
          ) : list?.length == 0 ? (
            <NoData />
          ) : (
            <>
              <div className="title">Cart...</div>
              {list?.map((item, index) => (
                <CartCard
                  key={index}
                  remove_cart={() => remove_item(item.ID)}
                  update_quantity={(qty) =>
                    update_quantity(item.ID, qty, item?.min_quantity)
                  }
                  qty={item.quantity}
                  Price={item?.Price}
                  prod_name={item?.P_name}
                  min_qty={item?.min_quantity}
                  product_cat={
                    consumable_product_list?.filter(
                      (v) => v?.id == item?.product_cat
                    )?.[0]?.name
                  }
                  color={item?.color}
                  url={item?.product_image?.[0]?.image_url}
                />
              ))}
              <div className="totals">
                <div className="totals-item">
                  <label>Subtotal</label>
                  <div className="totals-value" id="cart-subtotal">
                    {ReduceFunction(list, "Price", "quantity")}
                  </div>
                </div>
                <div className="totals-item">
                  <label>Tax (5%)</label>
                  <div className="totals-value" id="cart-tax">
                    {Number(
                      (ReduceFunction(list, "Price", "quantity") * 5) / 100
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="totals-item">
                  <label>Shipping</label>
                  <div className="totals-value" id="cart-shipping">
                    15.00
                  </div>
                </div>
                <div className="totals-item totals-item-total">
                  <label>Grand Total</label>
                  <div className="totals-value" id="cart-total">
                    {Number(
                      Number(ReduceFunction(list, "Price", "quantity")) +
                        Number(
                          (ReduceFunction(list, "Price", "quantity") * 5) / 100
                        )
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="checkout">
                <CustomButton
                  title={"Check Out"}
                  onClick={checkout}
                  loading={loading}
                />
              </div>
            </>
          )}

          {/* {list?.map((v, i) => (
            <CartCard key={i} />
          ))} */}
        </div>
      </Container>
    </Container>
  );
};

export default ShopCart;

{
  /* <!-- Product #1 --> */
}
{
  /* <div class="item">
            <div class="buttons">
                <RxCross2 size={20} />
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Common Projects</span>
              <span>Bball High</span>
              <span>White</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
              <CiSquareMinus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
            </div>

            <div class="total-price"><FaIndianRupeeSign />
            549</div>
          </div> */
}

{
  /* <!-- Product #2 --> */
}
{
  /* <div class="item">
            <div class="buttons">
              <span class="delete-btn">
                <RxCross2 size={20} />
              </span>
              <span class="like-btn">
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
              </span>
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Maison Margiela</span>
              <span>Future Sneakers</span>
              <span>White</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquareMinus size={24} />
              </button>
            </div>

            <div class="total-price">$870</div>
          </div> */
}

{
  /* <!-- Product #3 --> */
}
{
  /* <div class="item">
            <div class="buttons">
              <span class="delete-btn">
                <RxCross2 size={20} />
              </span>
              <span class="like-btn">
                <FaHeart
                  onClick={() => setHeart(!heart)}
                  cursor="pointer"
                  color={heart ? "red" : "gray"}
                  size={20}
                />
              </span>
            </div>

            <div class="image">
              <img src={img} alt="" />
            </div>

            <div class="description">
              <span>Our Legacy</span>
              <span>Brushed Scarf</span>
              <span>Brown</span>
            </div>

            <div class="quantity">
              <button class="plus-btn" type="button" name="button">
                <CiSquarePlus size={24} />
              </button>
              <input type="text" name="name" value="1" />
              <button class="minus-btn" type="button" name="button">
                <CiSquareMinus size={24} />
              </button>
            </div>

            <div class="total-price">$349</div>
          </div> */
}
