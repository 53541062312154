import { Container, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import { FaCartPlus, FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import store from "../../../../redux/store";
import {
  get_add_to_cart,
  get_consumable_list,
  get_consumable_product_list,
  get_consumable_product_mem_list,
  get_member_cart_list,
  get_member_product_list,
  remove_from_cart,
  update_cart,
} from "../../../../redux/dataSlice";
import { MdDeleteForever } from "react-icons/md";
import { ProductCard } from "../../../../components/ProductCard";
import { NoData } from "../../../../components/NoData";
import { toast } from "react-toastify";
import useDebounce from "../../../../utils/Hook/DebounceHook";

const MembersShopMain = () => {
  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [heart, setHeart] = useState(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("user   ====", user);

  console.log("====================================");
  console.log("list ====", list);
  console.log("====================================");

  const navigate = useNavigate();
  const {
    consumable_product_list,
    consumable_mem_product_list,
    consumable_mem_loading,
    mem_cart_list,
  } = useSelector((state) => state.allData);
  const obj = { sub_cat_id: state?.id, userid: user?.id };

  useEffect(() => {
    setList(mem_cart_list);
  }, [mem_cart_list]);

  useEffect(() => {
    store.dispatch(get_member_cart_list(obj));
    store.dispatch(get_consumable_product_mem_list(state?.id));
    store.dispatch(get_consumable_product_list(state?.id));
  }, []);

  const add_to_cart = async (id, qty) => {
    store.dispatch(
      get_add_to_cart({
        id,
        qty,
        navigationFunc: navigate("/let's-shop/cart", { state: state }),
        userId: user?.id,
      })
    );
  };

  const remove_item = async (id) => {
    store.dispatch(remove_from_cart({ id, userId: user?.id, obj }));
  };

  const debouncedUpdate = useDebounce((id, qty) => {
    store.dispatch(
      update_cart({
        id,
        qty,
        obj,
        userId: user?.id,
      })
    );
  }, 800);

  const setCount = (id, qty) => {
    setList((prevList) =>
      prevList.map((item) =>
        item.product_id === id ? { ...item, quantity: qty } : item
      )
    );
    debouncedUpdate(list?.filter((v, i) => v?.product_id == id)?.[0]?.ID, qty);
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state?.name}
          titlelist={[
            { name: "Home", to: "/" },
            { name: "Let's Shop", to: -1 },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            onClick={() => navigate("/let's-shop/cart", { state: state })}
            rightIcon={<FaCartPlus size={20} color="#fff" />}
            title={"Cart"}
          />
          <CustomButton title={"Orders"} bg={"brand.primary"} />
        </Flex>
      </Flex>
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {consumable_mem_loading ? (
          <Loader />
        ) : consumable_mem_product_list?.length == 0 ? (
          <NoData />
        ) : (
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 3, 3]}
          >
            {consumable_mem_product_list?.map((item, index) => (
              <ProductCard
                key={index}
                nav={(e) => {
                  // e.preventDefault();
                  navigate("/let's-shop/details", {
                    state: { ...state, pid: item?.ID },
                  });
                }}
                min_order={item?.min_quantity}
                prod_name={item?.P_name}
                qunatity={item?.Quantity}
                price={item?.Price}
                Discount={item?.Discount}
                product_cat={
                  consumable_product_list?.filter(
                    (v) => v?.id == item?.product_cat
                  )?.[0]?.name
                }
                title={item?.name}
                url={item?.P_image}
                add={true}
                count={
                  list?.filter((v, i) => v?.product_id == item?.ID)?.[0]
                    ?.quantity || 0
                }
                setCount={(qty) => setCount(item?.ID, qty)}
                AddToCart={() => {
                  console.log("dasfdswsdd", item?.ID);

                  add_to_cart(item?.ID, item?.min_quantity);
                }}
                remove_item={() =>
                  remove_item(
                    list?.filter((v, i) => v?.product_id == item?.ID)?.[0]?.ID
                  )
                }
                // removeCart = {}
                addLoading={loading}
                product_images={item?.product_image}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default MembersShopMain;
