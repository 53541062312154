import {
  Center,
  Container,
  Flex,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import BreadCrumName from "../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Loader } from "../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import ShowMoreText, {
  ListDataShow,
  Table1,
} from "../../../../components/Compnent";
import StatusComp from "../../../../components/StatusComp";
import CustomPopup from "../../../../components/CustomPopup";
import { BorderFunction } from "../../../../utils/utils";
import moment from "moment";
import { FeedBack, FeedBackbar } from "../../../../components/FeedBack";
import { toast } from "react-toastify";
import { downloadFile } from "../../../../utils/Hook/DownloadFile";

const UserServices = () => {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState("");
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const [charges, setCharges] = useState("");
  const { notification_list, notification_loading } = useSelector(
    (state) => state.allData
  );
  const [updateType, setUpdateType] = useState("");

  const [loading, setLoading] = useState(false);
  const { type } = useSelector((state) => state.otherData);
  const [list, setList] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();


  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.subCat_id) {
      setLoading(true);
      const body = new FormData();
      body.append("action", "listservices");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id);
      body.append("user_id", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setList(res.data);
      } else if (!res.success) {
        setList([]);
      }
    }
  };

  const update_status = async () => {
    onClose();
    try {
      setLoading(true);
      const body = new FormData();
      if (updateType == "Complete") {
        body.append("action", "user_complete");
        body.append("user_id", user?.id);
        body.append("id", id);
        body.append("complete_status", 1);
      } else {
        body.append("action", "updatePaymentStatus");
        body.append("payment_status", 1);
        body.append("id", id);
        body.append("user_id", user?.id);
      }

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();

      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        fetchData();
        setId("");
        setCharges("");
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("err ====", error);
    }
  };

  const update_feedback = async () => {
    if (data?.rating && data?.message && !data?.messError) {
      onClose1();
      try {
        // updateFeedback(id,feedback_status,feed)
        setLoading(true);
        const body = new FormData();
        body.append("action", "updateFeedback");
        body.append("user_id", user?.id);
        body.append("id", id);
        body.append("feed", data?.message);
        body.append("feedback_status", data?.rating);
        const responce = await fetch(
          process.env.REACT_APP_BASE_URL + "Advice_legal.php",
          { method: "POST", body: body }
        );
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          fetchData();
          setData({});
          setId("");
          toast.success(res?.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setData({
        ...data,
        ratingError: !data?.rating ? "Please Select Rating First" : "",
        messError: !data?.message ? "Please Enter Message" : "",
      });
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Services"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Get Advice", to: -2 },
          { name: state.name, to: -1 },
        ]}
      />

      <Container maxW={"container.md"} py={5}>
        {loading ? (
          <Loader />
        ) : (
          list?.map((item, index) => (
            <Stack
              key={index}
              borderRadius={10}
              py={2}
              px={5}
              mt={[3, 5]}
              cursor={"pointer"}
              position={"relative"}
              border={BorderFunction(item?.status)}
            >
              <StatusComp status={item?.status} />
              <ListDataShow
                f1={[4, 6]}
                f2={[7, 14]}
                title={"Supplier Name"}
                value={item?.Name}
              />
              <ListDataShow
                f1={[4, 6]}
                f2={[7, 14]}
                title={"Description"}
                value={item?.Description}
              />
              <ListDataShow
                f1={[4, 6]}
                f2={[7, 14]}
                title={"Charges"}
                value={item?.charges}
              />
              {item?.feedback_status > 0 && (
                <>
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Rating"}
                    value={<FeedBackbar rating={item?.feedback_status} />}
                  />
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Feed-Back"}
                    value={item?.feedback}
                  />
                </>
              )}

              <Flex
                w={"full"}
                justifyContent={["flex-start", "center"]}
                alignItems={["flex-start", "center"]}
                flexDirection={["column", "row"]}
                gap={[2, 2]}
              >
                {item.status == 1 && item?.payment_status == 0 && (
                  <CustomButton
                    title={"Pay Now"}
                    onClick={() => {
                      onOpen();
                      setId(item?.id);
                      setUpdateType("Pay Now");
                      setCharges(item?.charges);
                    }}
                    bg={"brand.primary"}
                  />
                )}
                {item?.payment_status == 1 && item?.complete_status == 0 && (
                  <CustomButton
                    title={"Complete"}
                    // onClick={pay_now}
                    onClick={() => {
                      onOpen();
                      setId(item?.id);
                      setUpdateType("Complete");
                    }}
                    bg={"brand.primary"}
                  />
                )}
                {item?.complete_status == 1 && item?.feedback_status == 0 && (
                  <CustomButton
                    title={"Feed Back"}
                    // onClick={pay_now}
                    bg={"brand.primary"}
                    onClick={() => {
                      setId(item?.id);
                      onOpen1();
                    }}
                  />
                )}
                {item?.image_url && (
                  <CustomButton
                    title={"View/Download"}
                    // onClick={pay_now}
                    onClick={() => downloadFile(item?.image_url)}
                    bg={"brand.text2"}
                  />
                )}
              </Flex>

              <Flex
                w={"full"}
                direction={"flex"}
                justifyContent={["flex-start", "flex-end"]}
                alignItems={["flex-start", "flex-end"]}
                flexDirection={["column", "row"]}
                gap={[2, 0]}
              >
                <Text
                  // w={"fit-content"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  w={"fit-content"}
                  textAlign={"right"}
                  flex={1}
                  color={"brand.primary"}
                  gap={4}
                  onClick={() => {
                    if (showTable == index + 1) {
                      setShowTable("");
                    } else {
                      setShowTable(index + 1);
                    }
                  }}
                >
                  {showTable == index + 1 ? "Less Status" : "Show Status"}
                </Text>
                {/* 
                <Text
                  // w={"100%"}
                  // flex={1}
                  textAlign={"right"}
                  color={"brand.text1"}
                  fontSize={[14, 15, 16]}
                  fontWeight={500}
                >
                  {item.date_time || "12-jan-2024 02:04 PM"}
                </Text> */}
              </Flex>

              {showTable == index + 1 && (
                <Table1
                  headData={[
                    "Sl No.",
                    "Provider Name",
                    "Status",
                    "Status Update Date",
                  ]}
                >
                  {item?.status_details?.map((v, i) => (
                    <Tr key={i}>
                      <Td>
                        <Center>{i + 1}</Center>
                      </Td>
                      <Td>
                        <Center>{v?.provider}</Center>
                      </Td>
                      <Td>
                        <Center>{v?.title}</Center>
                      </Td>
                      <Td>
                        <Center>
                          {moment(v?.datetime).format("DD-MMM-YYYY hh:mm A")}
                        </Center>
                      </Td>
                    </Tr>
                  ))}
                </Table1>
              )}
            </Stack>
          ))
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={update_status}
        b_name={updateType}
        onClose={onClose}
        title={updateType}
        mess={`Are you sure? You want to ${
          updateType == "Complete" ? "Complete this Service" : `Rs ${charges}`
        }  .`}
      />
      <CustomPopup
        isOpen={isOpen1}
        onClick={update_feedback}
        b_name={"Submit"}
        onClose={() => {
          onClose1();
          setData({});
        }}
        title={"Feedback"}
        mess={<FeedBack data={data} setData={setData} />}
      />
    </Container>
  );
};

export default UserServices;
