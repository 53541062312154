import {
  Center,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  SelectBox,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GridInputComponent } from "../../../../components/Compnent";
import moment from "moment";
import { DateValidator2, TypeOfVehicle } from "../../../../utils/utils";
const eighteenYearsAgo2 = moment().subtract(18, "years").format("YYYY-MM-DD");
const twentyFiveYearsAgo = moment().subtract(25, "years").format("YYYY");
const currentYear = moment().format("YYYY");
const vehicalRegex = /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/;
const alfaNumericWithoutSpace = /^[a-zA-Z0-9]+$/;

const AddInsurance = () => {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const mobile = localStorage.getItem("mobile");
  const { policy_company_list, other_policy_list } = useSelector(
    (state) => state.otherData
  );

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const [otherPolicy, setOtherPolicy] = useState("");

  useEffect(() => {
    setData({
      ...data,
      policy:
        state?.id == 0
          ? "Distributor Package Policy"
          : state?.id == 1
          ? "Employee Coverage Policy"
          : state?.id == 2
          ? "Motor Insurance"
          : "Others",
      policy_id: state?.id,
    });
  }, []);
  // setData({})

  const onAddInsurance = async () => {
    try {
      const validateData = DateValidator2(
        moment(data?.dob).format("DD/MM/YYYY"),
        moment(eighteenYearsAgo2).format("DD/MM/YYYY"),
        "01/01/1950",
        `Date of Birth cant be before ${"01/01/1950"}`,
        `Date of Birth cant be after ${moment(eighteenYearsAgo2).format(
          "DD-MMM-YYYY"
        )}`
      );
      if (data?.policy_id == 0) {
        if (
          data?.policy == null ||
          data?.policy == undefined ||
          data?.policy == "" ||
          !data?.company ||
          !data?.name ||
          !data?.address ||
          !data?.location ||
          !data?.p_name ||
          !data?.email ||
          !data?.address ||
          !data?.description ||
          !data?.occupancy ||
          !data?.firebuilding ||
          !data?.firecontents ||
          !data?.firestocks ||
          !data?.burglary ||
          !data?.eei ||
          !data?.mbd ||
          !data?.gascylinder ||
          !data?.moneyinsurance ||
          !data?.moneyintransit ||
          !data?.moneyinsafe ||
          !data?.fidelity ||
          !data?.tricycle ||
          !data?.liability ||
          !data?.workmen ||
          !data?.plateglass ||
          !data?.neonsign ||
          !data?.accident ||
          !data?.premium ||
          !data?.gst ||
          !data?.grosspremium
          // !data?.file.name
        ) {
          setData({
            ...data,
            policyMess:
              data?.policy == null ||
              data?.policy == undefined ||
              data?.policy == ""
                ? "Policy is required"
                : "",
            companyMess: !data?.company ? "Company is required" : "",
            nameMess: !data?.name ? "Name is required" : "",
            addressMess: !data?.address ? "Address is required" : "",
            locationMess: !data?.location ? "Location is required" : "",
            p_nameMess: !data?.p_name ? "Proprietory Name is required" : "",
            emailMess: !data?.email
              ? "Email is required"
              : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.email)
              ? "Please Enter a Vaild Email"
              : "",
            descriptionMess: !data?.description
              ? "Description is required"
              : "",
            occupancyMess: !data?.occupancy ? "Occupancy is required" : "",
            firebuildingMess: !data?.firebuilding
              ? "Fire-Building is required"
              : "",
            firecontentsMess: !data?.firecontents
              ? "Fire Contents Other than Stocks is required"
              : "",
            firestocksMess: !data?.firestocks ? "Fire-Stocks is required" : "",
            burglaryMess: !data?.burglary ? "Burglary is required" : "",
            eeiMess: !data?.eei ? "EEI is required" : "",
            mbdMess: !data?.mbd ? "MBD is required" : "",
            gascylinderMess: !data?.gascylinder
              ? "Gas Cylinders In Transit is required"
              : "",
            moneyinsuranceMess: !data?.moneyinsurance
              ? "Money Insurance is required"
              : "",
            moneyintransitMess: !data?.moneyintransit
              ? "Money in Transit is required"
              : "",
            moneyinsafeMess: !data?.moneyinsafe
              ? "Money in Safe/Till is required"
              : "",
            fidelityMess: !data?.fidelity
              ? "Fidelity Guarantee is required"
              : "",
            tricycleMess: !data?.tricycle ? "Tricycles is required" : "",
            liabilityMess: !data?.liability
              ? "Public Liability Insurance is required"
              : "",
            workmenMess: !data?.workmen
              ? "Workmen Compensation is required"
              : "",
            plateglassMess: !data?.plateglass ? "Plate Glass is required" : "",
            neonsignMess: !data?.neonsign ? "Neon Sign Board is required" : "",
            accidentMess: !data?.accident
              ? "Personal Accident is required"
              : "",
            premiumMess: !data?.premium ? "Total Net Premium is required" : "",
            gstMess: !data?.gst ? "Add: GST @18% is required" : "",
            grosspremiumMess: !data?.grosspremium
              ? "Gross Total Premium is required"
              : "",
            addressMess: !data?.address ? "Address is required" : "",
            // fileMess: !data?.file?.name ? "Document is required" : "",
          });
          return;
        }
      } else if (data?.policy_id == 1) {
        if (
          !data?.policy ||
          !data?.policy_id ||
          !data?.company ||
          !data?.name ||
          !data?.address ||
          !data?.fathername ||
          !data?.dob ||
          validateData !== true ||
          !data?.mobile ||
          !data?.aadhar ||
          !data?.district ||
          !data?.description ||
          !data?.pincode ||
          (data?.pincode && data?.pincode?.length != 6)
        ) {
          setData({
            ...data,
            policyMess: !data?.policy ? "Policy is required" : "",
            policyMess: !data?.policy_id
              ? "Select Group Policy is required"
              : "",
            companyMess: !data?.company ? "Company is required" : "",
            nameMess: !data?.name ? "Name is required" : "",
            fathernameMess: !data?.fathername
              ? "Father / Husband Name is required"
              : "",
            addressMess: !data?.address ? "Address is required" : "",
            dobMess: !data?.dob
              ? "Date of birth is required"
              : validateData !== true
              ? `${validateData[0]}`
              : "",

            descriptionMess: !data?.description
              ? "Description is required"
              : "",

            mobileMess: !data?.mobile ? "Mobile is required" : "",
            aadharMess: !data?.aadhar ? "Aadhar is required" : "",
            districtMess: !data?.district ? "District is required" : "",
            pincodeMess: !data?.pincode
              ? "Pincode is required"
              : data?.pincode?.length != 6
              ? "Pincode Must be 6 digit"
              : "",
          });
          return;
        }
      } else if (data?.policy_id == 2) {
        if (
          !data?.policy ||
          !data?.company ||
          // !data?.docs?.name ||
          !data?.name ||
          !data?.vehiclenumber ||
          // (data?.vehiclenumber && ) ||
          !vehicalRegex.test(data?.vehiclenumber) ||
          !data?.vehicletype ||
          !data?.registrationnumber ||
          !data?.model ||
          !data?.chassisnumber ||
          !data?.mfgyear ||
          data?.mfgyear?.length != 4 ||
          !data?.description ||
          !data?.email ||
          !data?.ida
          // !data?.file?.name
        ) {
          setData({
            ...data,
            policyMess: !data?.policy ? "Policy is required" : "",
            companyMess: !data?.company ? "Company is required" : "",
            // docsMess: !data?.docs?.name ? "Image/Document is required" : "",
            nameMess: !data?.name ? "Name is required" : "",
            vehiclenumberMess: !data?.vehiclenumber
              ? "Vehical Number is required"
              : !vehicalRegex.test(data?.vehiclenumber) ||
                data?.vehiclenumber?.length <= 8
              ? "Please Enter a Valid Vehicle Number"
              : "",
            vehicletypeMess: !data?.vehicletype
              ? "Type of Vehicle is required"
              : "",
            registrationnumberMess: !data?.registrationnumber
              ? "Registration Number is required"
              : "",
            modelMess: !data?.model ? "Make/Model is required" : "",
            chassisnumberMess: !data?.chassisnumber
              ? "Chassis Number is required"
              : "",
            mfgyearMess: !data?.mfgyear
              ? "Mfg. year is required"
              : data?.mfgyear?.length != 4 ||
                Number(data?.mfgyear) < Number(twentyFiveYearsAgo) ||
                Number(data?.mfgyear) > Number(Number(currentYear) + 1)
              ? "Please Enter Valid Mfg. Year"
              : "",
            emailMess: !data?.email
              ? "Email is required"
              : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.email)
              ? "Please Enter a Vaild Email"
              : "",
            idaMess: !data?.ida ? "IDA is required" : "",
            // fileMess: !data?.file?.name ? "Document is required" : "",
            descriptionMess: !data?.description
              ? "Description is required"
              : "",
          });
          return;
        }
      } else if (data?.policy_id == 3) {
        if (
          !data?.policy ||
          !otherPolicy ||
          !data?.company ||
          !data?.name ||
          !data?.description ||
          !data?.file?.name
        ) {
          const obj = {
            policyMess: !data?.policy ? "Policy is required" : "",
            otherPolicyMess: !otherPolicy ? "Other Policy is required" : "",
            companyMess: !data?.company ? "Company is required" : "",
            nameMess: !data?.name ? "Name is required" : "",
            fileMess: !data?.file?.name ? "Document is required" : "",
            descriptionMess: !data?.description
              ? "Description is required"
              : "",
          };
          if (
            (otherPolicy == "Life Insurance" &&
              (!data?.address ||
                !data?.dob ||
                validateData !== true ||
                !data?.mobile ||
                !data?.sonwife ||
                !data?.lifecover ||
                !data?.coveragetilldate)) ||
            !data?.paymenttype
          ) {
            obj.addressMess = !data?.address ? "Address is required" : "";
            obj.dobMess = !data?.dob
              ? "Date of birth is required"
              : validateData !== true
              ? `${validateData[0]}`
              : "";
            obj.mobileMess = !data?.mobile ? "Mobile is required" : "";
            obj.sonwifeMess = !data?.sonwife ? "Son/Wife is required" : "";
            obj.lifecoverMess = !data?.lifecover
              ? "Life Cover is required"
              : "";
            obj.coveragetilldateMess = !data?.coveragetilldate
              ? "Coverage Till Date is required"
              : "";
            obj.paymenttypeMess = !data?.paymenttype
              ? "Payment Type is required"
              : "";
          } else if (
            otherPolicy == "Health Insurance" &&
            (!data?.dob ||
              !data?.mobile ||
              !data?.lifecover ||
              !data?.coveragetilldate ||
              !data?.paymenttype ||
              !data?.gender ||
              !data?.spouse ||
              !data?.daughter ||
              !data?.father ||
              !data?.age)
          ) {
            obj.dobMess = !data?.dob
              ? "Date of birth is required"
              : validateData !== true
              ? `${validateData[0]}`
              : "";
            obj.mobileMess = !data?.mobile ? "Mobile is required" : "";
            obj.sonwifeMess = !data?.sonwife ? "Son/Wife is required" : "";
            obj.lifecoverMess = !data?.lifecover
              ? "Life Cover is required"
              : "";
            obj.coveragetilldateMess = !data?.coveragetilldate
              ? "Coverage Till Date is required"
              : "";
            obj.paymenttypeMess = !data?.paymenttype
              ? "Payment Type is required"
              : "";
            obj.genderMess = !data?.gender ? "Gender is required" : "";
            obj.spouseMess = !data?.spouse ? "Spouse is required" : "";
            obj.daughterMess = !data?.daughter ? "Daughter is required" : "";
            obj.fatherMess = !data?.father ? "Father is required" : "";
            obj.ageMess = !data?.age ? "Age is required" : "";
          }
          setData({ ...data, ...obj });
          return;
        }
      }
      setLoading(true);
      const body = new FormData();
      if (data?.policy_id == 0) {
        body.append("action", "distributor");
        body.append("category_id", 8);
        body.append("sub_category_id", data?.policy_id);
        body.append("userid", user?.id);
        body.append("name", data?.name);
        body.append("address", data?.address);
        body.append("location", data?.location);
        body.append("insurance", data?.company);
        body.append("pname", data?.p_name);
        body.append("email", data?.email);
        body.append("description", data?.description);
        body.append("occurancy", data?.occupancy);
        body.append("fire_building", data?.firebuilding);
        body.append("fire_content", data?.firecontents);
        body.append("fire_stock", data?.firestocks);
        body.append("burglary", data?.burglary);
        body.append("eei", data?.eei);
        body.append("mbd", data?.mbd);
        body.append("gas_cylinder", data?.gascylinder);
        body.append("money_insurance", data?.moneyinsurance);
        body.append("money_transit", data?.moneyintransit);
        body.append("money_safe", data?.moneyinsafe);
        body.append("Fidelity_guarantee", data?.fidelity);
        body.append("tricycle", data?.tricycle);
        body.append("Public_liability", data?.liability);
        body.append("Compensation", data?.workmen);
        body.append("Plate_glass", data?.plateglass);
        body.append("Neon_sign", data?.neonsign);
        body.append("Personal_accident", data?.accident);
        body.append("Total_net", data?.premium);
        body.append("gst", data?.gst);
        body.append("Gross_total", data?.grosspremium);
      } else if (data?.policy_id == 1) {
        body.append("action", "employee");
        body.append("category_id", 8);
        body.append("sub_category_id", data?.policy_id);
        body.append("userid", user?.id);
        body.append("insurance", data?.company);
        body.append("policy", data?.group_policy);
        body.append("name", data?.name);
        body.append("address", data?.address);
        body.append("father_husband_name", data?.fathername);
        body.append("dob", data?.dob);
        body.append("mobile_number", data?.mobile);
        body.append("adhar_no", data?.aadhar);
        body.append("district", data?.district);
        body.append("state", "Telangana");
        body.append("pin", data?.pincode);
        body.append("description", data?.description);
        body.append("image", data?.file);
      } else if (data?.policy_id == 2) {
        body.append("action", "motor");
        body.append("category_id", 8);
        body.append("sub_category_id", data?.policy_id);
        body.append("userid", user?.id);
        body.append("policy", data?.group_policy);
        body.append("insurance", data?.company);
        body.append("typ_vehicle", data?.vehicletype);
        body.append("reg_num", data?.vehiclenumber);
        body.append("model", data?.model);
        body.append("chasis", data?.chassisnumber);
        body.append("mfg_year", data?.mfgyear);
        body.append("name", data?.name);
        body.append("email_id", data?.email);
        body.append("ida", data?.ida);
        body.append("image", data?.docs);
        body.append("description", data?.description);
      } else if (data?.policy_id == 3) {
        if (otherPolicy == "Life Insurance") {
          body.append("action", "life");
          body.append("category_id", 8);
          body.append("sub_category_id", data?.policy_id);
          body.append("userid", user?.id);
          body.append("insurance", data?.company);
          body.append("name", data?.name);
          body.append("address", data?.address);
          body.append("image", data?.file);
          body.append("son_wife", data?.sonwife);
          body.append("dob", data?.dob);
          body.append("mobile_number", data?.mobile);
          body.append("life_cover", data?.lifecover);
          body.append("coverage", data?.coveragetilldate);
          body.append("payment_type", data?.paymenttype);
          body.append("description", data?.description);
        } else if (otherPolicy == "Health Insurance") {
          body.append("action", "health");
          body.append("category_id", 8);
          body.append("sub_category_id", data?.policy_id);
          body.append("userid", user?.id);
          body.append("insurance", data?.company);
          body.append("name", data?.name);
          body.append("gender", data?.gender);
          body.append("spouse", data?.spouse);
          body.append("daughter", data?.daughter);
          body.append("father", data?.father);
          body.append("dob", data?.dob);
          body.append("mobile_number", data?.mobile);
          body.append("life_cover", data?.lifecover);
          body.append("coverage", data?.coveragetilldate);
          body.append("payment", data?.paymenttype);
          body.append("description", data?.description);
          body.append("age", data?.age);
          body.append("image", data?.file);
        }
      }
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Insurance.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
        setData({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Add Insurance"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Insurance", to: "/insurance" },
        ]}
      />

      <Container maxW={"container.sm"} p={4}>
        <SelectBox
          req={true}
          label={"Policy"}
          options={
            <>
              <option value={"0"}>Distributor Package Policy</option>
              <option value={"1"}>Employee Coverage Policy</option>
              <option value={"2"}>Motor Insurance</option>
              <option value={"3"}>Others</option>
            </>
          }
          errorMess={data?.policyMess}
          value={data?.policy_id}
          onChange={(e) => {
            setData({
              ...data,
              policy:
                e.target.value == 0
                  ? "Distributor Package Policy"
                  : e.target.value == 1
                  ? "Employee Coverage Policy"
                  : e.target.value == 2
                  ? "Motor Insurance"
                  : e.target.value == 3
                  ? "Others"
                  : "",
              policy_id: e.target.value,
              policyMess: "",
            });
            setOtherPolicy("");
          }}
        />
        {/* {data?.policy == "Employee Coverage Policy" && (
          <SelectBox
            req={true}
            errorMess={data?.group_policyMess}
            value={data?.group_policy}
            onChange={(e) =>
              setData({
                ...data,
                group_policy: e.target.value,
                group_policyMess: "",
              })
            }
            label={"Select Group Policy"}
            placeholder={"Select"}
            options={
              <>
                <option value={"Delivery Package Policy"}>
                  Delivery Package Policy
                </option>
                <option value={"Employee Coverage Policy"}>
                  Employee Coverage Policy
                </option>
                <option value={"Motor Insurance"}>Motor Insurance</option>
                <option value={"Others"}>Others</option>
              </>
            }
          />
        )} */}
        {data?.policy == "Others" && (
          <SelectBox
            label={"Others Policy"}
            placeholder={"Select"}
            options={other_policy_list?.map((v, i) => (
              <option key={i} value={v.vehicle_name}>
                {v.vehicle_name}
              </option>
            ))}
            value={otherPolicy}
            onChange={(e) => setOtherPolicy(e.target.value)}
            errorMess={data?.otherPolicyMess}
          />
        )}

        <SelectBox
          label={"Company List"}
          placeholder={"Select"}
          options={policy_company_list?.map((item, index) => (
            <option key={index} value={item.provider}>
              {item.provider}
            </option>
          ))}
          value={data?.company}
          onChange={(v) => {
            setData({ ...data, company: v.target.value, companyMess: "" });
          }}
          errorMess={data?.companyMess}
        />
        {/* {data?.policy == "Employee Coverage Policy" ||
          (data?.policy == "Motor Insurance" && (
            <SelectImage
              label={"Image/Document"}
              value={data?.docs}
              filename={data?.docs?.name}
              onChange={(v) => {
                setData({ ...data, docs: v.target.files[0], docsMess: "" });
              }}
              errorMess={data?.docsMess}
            />
          ))} */}
        <InputBox
          req={true}
          label={"Name"}
          placeholder={"Name"}
          value={data.name}
          onChange={(v) => {
            const input = v.target.value.replace(/\s\s+/g, " ");
            setData({ ...data, name: input, nameMess: "" });
          }}
          errorMess={data.nameMess}
        />
        <>
          {(otherPolicy == "Life Insurance" ||
            data?.policy == "Employee Coverage Policy") && (
            <InputBox
              req={true}
              label={"Address"}
              placeholder={"Address"}
              value={data.address}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  address: input,
                  addressMess: "",
                });
              }}
              errorMess={data.addressMess}
            />
          )}
          {(otherPolicy == "Life Insurance" ||
            otherPolicy == "Health Insurance") && (
            <InputBox
              req={true}
              max={eighteenYearsAgo2}
              label={"Date of Birth"}
              placeholder={"Date of Birth"}
              type={"date"}
              value={data.dob}
              onChange={(v) => {
                setData({
                  ...data,
                  dob: v.target.value,
                  dobMess: "",
                });
              }}
              errorMess={data.dobMess}
            />
          )}
          {(otherPolicy == "Life Insurance" ||
            otherPolicy == "Health Insurance") && (
            <InputBox
              label={"Mobile Number"}
              placeholder={"Mobile Number"}
              value={data.mobile}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 10) {
                  setData({
                    ...data,
                    mobile: input,
                    mobileMess: "",
                  });
                }
              }}
              errorMess={data.mobileMess}
            />
          )}
          {otherPolicy == "Life Insurance" && (
            <InputBox
              label={"Son/Wife"}
              placeholder={"Son/Wife"}
              value={data.sonwife}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  sonwife: input,
                  sonwifeMess: "",
                });
              }}
              errorMess={data.sonwifeMess}
            />
          )}
          {(otherPolicy == "Life Insurance" ||
            otherPolicy == "Health Insurance") && (
            <InputBox
              label={"Life Cover"}
              placeholder={"Life Cover"}
              value={data.lifecover}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  lifecover: input,
                  lifecoverMess: "",
                });
              }}
              errorMess={data.lifecoverMess}
            />
          )}
          {(otherPolicy == "Life Insurance" ||
            otherPolicy == "Health Insurance") && (
            <InputBox
              label={"Coverage Till Date"}
              placeholder={"Coverage Till Date"}
              type={"Date"}
              value={data.coveragetilldate}
              onChange={(v) => {
                setData({
                  ...data,
                  coveragetilldate: v.target.value,
                  coveragetilldateMess: "",
                });
              }}
              errorMess={data.coveragetilldateMess}
            />
          )}
          {(otherPolicy == "Life Insurance" ||
            otherPolicy == "Health Insurance") && (
            <InputBox
              label={"Payment Type"}
              placeholder={"Payment Type"}
              value={data.paymenttype}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  paymenttype: input,
                  paymenttypeMess: "",
                });
              }}
              errorMess={data.paymenttypeMess}
            />
          )}
          {otherPolicy == "Health Insurance" && (
            <GridInputComponent
              p={[0, 0]}
              mt={5}
              content={
                <RadioGroup
                  value={data.gender}
                  onChange={(e) => {
                    setData({ ...data, gender: e, genderMess: "" });
                  }}
                  errorMess={data?.genderMess}
                >
                  <Stack spacing={5} direction="row">
                    <Flex color={"brand.primary"}> Gender</Flex>
                    <Radio colorScheme="purple" value="1">
                      Male
                    </Radio>
                    <Radio colorScheme="purple" value="2">
                      Female
                    </Radio>
                  </Stack>
                  {data?.genderMess && (
                    <Text mt={2} color={"#f00"} fontSize={14}>
                      {data?.genderMess}
                    </Text>
                  )}
                </RadioGroup>
              }
            />
          )}
          {otherPolicy == "Health Insurance" && (
            <InputBox
              label={"Spouse"}
              placeholder={"Spouse"}
              value={data.spouse}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  spouse: input,
                  spouseMess: "",
                });
              }}
              errorMess={data.spouseMess}
            />
          )}
          {otherPolicy == "Health Insurance" && (
            <InputBox
              label={"Daughter"}
              placeholder={"Daughter"}
              value={data.daughter}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  daughter: input,
                  daughterMess: "",
                });
              }}
              errorMess={data.daughterMess}
            />
          )}
          {otherPolicy == "Health Insurance" && (
            <InputBox
              label={"Father"}
              placeholder={"Father"}
              value={data.father}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  father: input,
                  fatherMess: "",
                });
              }}
              errorMess={data.fatherMess}
            />
          )}
          {otherPolicy == "Health Insurance" && (
            <InputBox
              label={"Your Age"}
              placeholder={"Your Age"}
              value={data.age}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 2) {
                  setData({
                    ...data,
                    age: input,
                    ageMess: "",
                  });
                }
              }}
              errorMess={data.ageMess}
            />
          )}
        </>
        <>
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Vehicle Number"}
              placeholder={"Vehicle Number"}
              value={data.vehiclenumber}
              onChange={(v) => {
                const input1 = v.target.value?.toUpperCase();
                if (vehicalRegex.test(input1) || input1 === "") {
                  setData({
                    ...data,
                    vehiclenumber: input1,
                    vehiclenumberMess: "",
                  });
                }
              }}
              errorMess={data.vehiclenumberMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <SelectBox
              value={data.vehicletype}
              onChange={(v) => {
                setData({
                  ...data,
                  vehicletype: v.target.value,
                  vehicletypeMess: "",
                });
              }}
              errorMess={data.vehicletypeMess}
              label={"Type of Vehicle"}
              // placeholder={"Select"}
              options={
                <>
                  <option hidden="" value="">
                    SELECT
                  </option>
                  {TypeOfVehicle?.map((val, ind) => (
                    <option key={ind} value={val?.value}>
                      {val?.lable}
                    </option>
                  ))}
                </>
              }
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Registration Number"}
              placeholder={"Registration Number"}
              value={data.registrationnumber}
              onChange={(v) => {
                const input = v.target.value?.toUpperCase();
                if (alfaNumericWithoutSpace.test(input) || input === "") {
                  setData({
                    ...data,
                    registrationnumber: input,
                    registrationnumberMess: "",
                  });
                }
              }}
              errorMess={data.registrationnumberMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Make/Model"}
              placeholder={"Make/Model"}
              value={data.model}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  model: input,
                  modelMess: "",
                });
              }}
              errorMess={data.modelMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Chassis Number"}
              placeholder={"Chassis Number"}
              value={data.chassisnumber}
              onChange={(v) => {
                const input = v.target.value?.toUpperCase();
                if (alfaNumericWithoutSpace.test(input) || input === "") {
                  setData({
                    ...data,
                    chassisnumber: input,
                    chassisnumberMess: "",
                  });
                }
              }}
              errorMess={data.chassisnumberMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Mfg. year"}
              // type={"year"}
              placeholder={"Mfg. year"}
              value={data.mfgyear}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 4) {
                  setData({
                    ...data,
                    mfgyear: input,
                    mfgyearMess: "",
                  });
                }
              }}
              errorMess={data.mfgyearMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"Email ID"}
              placeholder={"Email ID"}
              value={data.email}
              onChange={(v) => {
                const input = v.target.value.trim();
                setData({ ...data, email: input, emailMess: "" });
              }}
              errorMess={data.emailMess}
            />
          )}
          {data?.policy == "Motor Insurance" && (
            <InputBox
              label={"I D A"}
              placeholder={"I D A"}
              value={data.ida}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  ida: input,
                  idaMess: "",
                });
              }}
              errorMess={data.idaMess}
            />
          )}
        </>
        <>
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              label={"Father/Husband Name"}
              placeholder={"Father/Husband Name"}
              value={data.fathername}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  fathername: input,
                  fathernameMess: "",
                });
              }}
              errorMess={data.fathernameMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              req={true}
              max={eighteenYearsAgo2}
              label={"Date of Birth"}
              placeholder={"Date of Birth"}
              type={"date"}
              value={data.dob}
              onChange={(v) => {
                setData({ ...data, dob: v.target.value, dobMess: "" });
              }}
              errorMess={data.dobMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              label={"Mobile Number"}
              placeholder={"Mobile Number"}
              value={data.mobile}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 10) {
                  setData({
                    ...data,
                    mobile: input,
                    mobileMess: "",
                  });
                }
              }}
              errorMess={data.mobileMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              label={"Aadhaar Number"}
              placeholder={"Aadhaar Number"}
              value={data.aadhar}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 12) {
                  setData({ ...data, aadhar: input, aadharMess: "" });
                }
              }}
              errorMess={data.aadharMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              req={true}
              label={"District"}
              placeholder={"District"}
              value={data.district}
              onChange={(v) => {
                const input = v.target.value.replace(/\s\s+/g, " ");
                setData({
                  ...data,
                  district: input,
                  districtMess: "",
                });
              }}
              errorMess={data.districtMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              label={"State"}
              placeholder={"State"}
              value={"Telangana"}
              // onChange={(v) => {
              //   setData({ ...data, district: v.target.value, districtMess: "" });
              // }}
              // errorMess={data.districtMess}
            />
          )}
          {data?.policy == "Employee Coverage Policy" && (
            <InputBox
              req={true}
              label={"Pincode"}
              placeholder={"Pincode"}
              value={data.pincode}
              onChange={(v) => {
                const input = v.target.value.replace(/\D/g, "");
                if (input.length <= 6) {
                  setData({ ...data, pincode: input, pincodeMess: "" });
                }
              }}
              errorMess={data.pincodeMess}
            />
          )}
        </>
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            req={true}
            label={"Address"}
            placeholder={"Address"}
            value={data.address}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({ ...data, address: input, addressMess: "" });
            }}
            errorMess={data.addressMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            req={true}
            label={"Location"}
            placeholder={"Location"}
            value={data.location}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({ ...data, location: input, locationMess: "" });
            }}
            errorMess={data.locationMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            req={true}
            label={"Proprietory Name"}
            placeholder={"Proprietory Name"}
            value={data.p_name}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({ ...data, p_name: input, p_nameMess: "" });
            }}
            errorMess={data.p_nameMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Email ID"}
            placeholder={"Email ID"}
            value={data.email}
            onChange={(v) => {
              const input = v.target.value.trim();
              setData({ ...data, email: input, emailMess: "" });
            }}
            errorMess={data.emailMess}
          />
        )}
        {/* {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Description"}
            placeholder={"Description"}
            value={data.description}
            area={true}
            req={true}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                description: input,
                descriptionMess: "",
              });
            }}
            errorMess={data.descriptionMess}
          />
        )} */}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Occupancy"}
            placeholder={"Occupancy"}
            value={data.occupancy}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                occupancy: input,
                occupancyMess: "",
              });
            }}
            errorMess={data.occupancyMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Fire-Building"}
            placeholder={"Fire-Building"}
            value={data.firebuilding}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                firebuilding: input,
                firebuildingMess: "",
              });
            }}
            errorMess={data.firebuildingMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Fire Contents Other than Stocks"}
            placeholder={"Fire Contents Other than Stocks"}
            value={data.firecontents}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                firecontents: input,
                firecontentsMess: "",
              });
            }}
            errorMess={data.firecontentsMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Fire-Stocks"}
            placeholder={"Fire-Stocks"}
            value={data.firestocks}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                firestocks: input,
                firestocksMess: "",
              });
            }}
            errorMess={data.firestocksMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Burglary"}
            placeholder={"Burglary"}
            value={data.burglary}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                burglary: input,
                burglaryMess: "",
              });
            }}
            errorMess={data.burglaryMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"EEI"}
            placeholder={"EEI"}
            value={data.eei}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                eei: input,
                eeiMess: "",
              });
            }}
            errorMess={data.eeiMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"MBD"}
            placeholder={"MBD"}
            value={data.mbd}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                mbd: input,
                mbdMess: "",
              });
            }}
            errorMess={data.mbdMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Gas Cylinders In Transit"}
            placeholder={"Gas Cylinders In Transit"}
            value={data.gascylinder}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                gascylinder: input,
                gascylinderMess: "",
              });
            }}
            errorMess={data.gascylinderMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Money Insurance"}
            placeholder={"Money Insurance"}
            value={data.moneyinsurance}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                moneyinsurance: input,
                moneyinsuranceMess: "",
              });
            }}
            errorMess={data.moneyinsuranceMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Money in Transit"}
            placeholder={"Money in Transit"}
            value={data.moneyintransit}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                moneyintransit: input,
                moneyintransitMess: "",
              });
            }}
            errorMess={data.moneyintransitMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Money in Safe/Till"}
            placeholder={"Money in Safe/Till"}
            value={data.moneyinsafe}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                moneyinsafe: input,
                moneyinsafeMess: "",
              });
            }}
            errorMess={data.moneyinsafeMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Fidelity Guarantee"}
            placeholder={"Fidelity Guarantee"}
            value={data.fidelity}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                fidelity: input,
                fidelityMess: "",
              });
            }}
            errorMess={data.fidelityMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Tricycles"}
            placeholder={"Tricycles"}
            value={data.tricycle}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                tricycle: input,
                tricycleMess: "",
              });
            }}
            errorMess={data.tricycleMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Public Liability Insurance"}
            placeholder={"Public Liability Insurance"}
            value={data.liability}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                liability: input,
                liabilityMess: "",
              });
            }}
            errorMess={data.liabilityMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Workmen Compensation"}
            placeholder={"Workmen Compensation"}
            value={data.workmen}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                workmen: input,
                workmenMess: "",
              });
            }}
            errorMess={data.workmenMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Plate Glass"}
            placeholder={"Plate Glass"}
            value={data.plateglass}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                plateglass: input,
                plateglassMess: "",
              });
            }}
            errorMess={data.plateglassMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Neon Sign Board"}
            placeholder={"Neon Sign Board"}
            value={data.neonsign}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                neonsign: input,
                neonsignMess: "",
              });
            }}
            errorMess={data.neonsignMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Personal Accident"}
            placeholder={"Personal Accident"}
            value={data.accident}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                accident: input,
                accidentMess: "",
              });
            }}
            errorMess={data.accidentMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Total Net Premium"}
            placeholder={"Total Net Premium"}
            value={data.premium}
            onChange={(v) => {
              // const input = v.target.value.replace(/\s\s+/g, " ");
              const inputValue = v.target.value;
              if (
                (inputValue?.length <= 15 &&
                  /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
                inputValue == 0 ||
                !data.premium
              ) {
                setData({
                  ...data,
                  premium: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)
                    ? inputValue
                    : "",
                  premiumMess: "",
                });
              }
            }}
            errorMess={data.premiumMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Add: GST @18%"}
            placeholder={"Add: GST @18%"}
            value={data.gst}
            onChange={(v) => {
              const inputValue = v.target.value;
              if (
                (inputValue?.length <= 15 &&
                  /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
                inputValue == 0 ||
                !data.premium
              ) {
                setData({
                  ...data,
                  gst: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)
                    ? inputValue
                    : "",
                  gstMess: "",
                });
              }
            }}
            errorMess={data.gstMess}
          />
        )}
        {data?.policy == "Distributor Package Policy" && (
          <InputBox
            label={"Gross Total Premium"}
            placeholder={"Gross Total Premium"}
            value={data.grosspremium}
            onChange={(v) => {
              const inputValue = v.target.value;
              if (
                (inputValue?.length <= 15 &&
                  /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
                inputValue == 0 ||
                !data.premium
              ) {
                setData({
                  ...data,
                  grosspremium: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(
                    inputValue
                  )
                    ? inputValue
                    : "",
                  grosspremiumMess: "",
                });
              }
            }}
            errorMess={data.grosspremiumMess}
          />
        )}
        {(data?.policy == "Employee Coverage Policy" ||
          data?.policy == "Others") && (
          <SelectImage
            label={"Upload Document"}
            value={data.file?.name}
            filename={data.file?.name}
            onChange={(v) => {
              setData({ ...data, file: v.target.files[0], fileMess: "" });
            }}
            errorMess={data.fileMess}
          />
        )}
        <InputBox
          label={"Description"}
          placeholder={"Description"}
          value={data.description}
          area={true}
          req={true}
          onChange={(v) => {
            const input = v.target.value.replace(/\s\s+/g, " ");
            setData({
              ...data,
              description: input,
              descriptionMess: "",
            });
          }}
          errorMess={data.descriptionMess}
        />
        <Center mt={6}>
          <CustomButton
            title={"Submit"}
            onClick={() => onAddInsurance()}
            loading={loading}
          />
        </Center>
      </Container>
    </Container>
  );
};

export default AddInsurance;

// import {
//   Center,
//   Container,
//   Flex,
//   Radio,
//   RadioGroup,
//   Stack,
//   Text,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import BreadCrumName from "../../../../components/BreadCrumName";
// import {
//   CustomButton,
//   InputBox,
//   SelectBox,
//   SelectImage,
// } from "../../../../utils/LayoutUtils";
// import { toast } from "react-toastify";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { GridInputComponent } from "../../../../components/Compnent";
// import moment from "moment";
// import { DateValidator2, TypeOfVehicle } from "../../../../utils/utils";
// const eighteenYearsAgo2 = moment().subtract(18, "years").format("YYYY-MM-DD");
// const twentyFiveYearsAgo = moment().subtract(25, "years").format("YYYY");
// const currentYear = moment().format("YYYY");
// const vehicalRegex = /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/;
// const alfaNumericWithoutSpace = /^[a-zA-Z0-9]+$/;

// const AddInsurance = () => {
//   const { state } = useLocation();
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const mobile = localStorage.getItem("mobile");
//   const { policy_company_list, other_policy_list } = useSelector(
//     (state) => state.otherData
//   );


//   const loggedInUser = localStorage.getItem("user");
//   const user = JSON.parse(loggedInUser);

//   const [otherPolicy, setOtherPolicy] = useState("");

//   useEffect(() => {
//     setData({
//       ...data,
//       policy:
//         state?.id == 0
//           ? "Distributor Package Policy"
//           : state?.id == 1
//           ? "Employee Coverage Policy"
//           : state?.id == 2
//           ? "Motor Insurance"
//           : "Others",
//       policy_id: state?.id,
//     });
//   }, []);
//   // setData({})

//   const onAddInsurance = async () => {
//     try {
//       const validateData = DateValidator2(
//         moment(data?.dob).format("DD/MM/YYYY"),
//         moment(eighteenYearsAgo2).format("DD/MM/YYYY"),
//         "01/01/1950",
//         `Date of Birth cant be before ${"01/01/1950"}`,
//         `Date of Birth cant be after ${moment(eighteenYearsAgo2).format(
//           "DD-MMM-YYYY"
//         )}`
//       );
//       if (data?.policy_id == 0) {
//         if (
//           data?.policy == null ||
//           data?.policy == undefined ||
//           data?.policy == "" ||
//           !data?.company ||
//           !data?.name ||
//           !data?.address ||
//           !data?.location ||
//           !data?.p_name ||
//           // !data?.email ||
//           // !data?.address ||
//           !data?.description
//           // !data?.occupancy ||
//           // !data?.firebuilding ||
//           // !data?.firecontents ||
//           // !data?.firestocks ||
//           // !data?.burglary ||
//           // !data?.eei ||
//           // !data?.mbd ||
//           // !data?.gascylinder ||
//           // !data?.moneyinsurance ||
//           // !data?.moneyintransit ||
//           // !data?.moneyinsafe ||
//           // !data?.fidelity ||
//           // !data?.tricycle ||
//           // !data?.liability ||
//           // !data?.workmen ||
//           // !data?.plateglass ||
//           // !data?.neonsign ||
//           // !data?.accident ||
//           // !data?.premium ||
//           // !data?.gst ||
//           // !data?.grosspremium
//           // !data?.file.name
//         ) {
//           setData({
//             ...data,
//             policyMess:
//               data?.policy == null ||
//               data?.policy == undefined ||
//               data?.policy == ""
//                 ? "Policy is required"
//                 : "",
//             companyMess: !data?.company ? "Company is required" : "",
//             nameMess: !data?.name ? "Name is required" : "",
//             addressMess: !data?.address ? "Address is required" : "",
//             locationMess: !data?.location ? "Location is required" : "",
//             p_nameMess: !data?.p_name ? "Proprietory Name is required" : "",
//             // emailMess: !data?.email
//             //   ? "Email is required"
//             //   : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.email)
//             //   ? "Please Enter a Vaild Email"
//             //   : "",
//             descriptionMess: !data?.description
//               ? "Description is required"
//               : "",
//             // occupancyMess: !data?.occupancy ? "Occupancy is required" : "",
//             // firebuildingMess: !data?.firebuilding
//             //   ? "Fire-Building is required"
//             //   : "",
//             // firecontentsMess: !data?.firecontents
//             //   ? "Fire Contents Other than Stocks is required"
//             //   : "",
//             // firestocksMess: !data?.firestocks ? "Fire-Stocks is required" : "",
//             // burglaryMess: !data?.burglary ? "Burglary is required" : "",
//             // eeiMess: !data?.eei ? "EEI is required" : "",
//             // mbdMess: !data?.mbd ? "MBD is required" : "",
//             // gascylinderMess: !data?.gascylinder
//             //   ? "Gas Cylinders In Transit is required"
//             //   : "",
//             // moneyinsuranceMess: !data?.moneyinsurance
//             //   ? "Money Insurance is required"
//             //   : "",
//             // moneyintransitMess: !data?.moneyintransit
//             //   ? "Money in Transit is required"
//             //   : "",
//             // moneyinsafeMess: !data?.moneyinsafe
//             //   ? "Money in Safe/Till is required"
//             //   : "",
//             // fidelityMess: !data?.fidelity
//             //   ? "Fidelity Guarantee is required"
//             //   : "",
//             // tricycleMess: !data?.tricycle ? "Tricycles is required" : "",
//             // liabilityMess: !data?.liability
//             //   ? "Public Liability Insurance is required"
//             //   : "",
//             // workmenMess: !data?.workmen
//             //   ? "Workmen Compensation is required"
//             //   : "",
//             // plateglassMess: !data?.plateglass ? "Plate Glass is required" : "",
//             // neonsignMess: !data?.neonsign ? "Neon Sign Board is required" : "",
//             // accidentMess: !data?.accident
//             //   ? "Personal Accident is required"
//             //   : "",
//             // premiumMess: !data?.premium ? "Total Net Premium is required" : "",
//             // gstMess: !data?.gst ? "Add: GST @18% is required" : "",
//             // grosspremiumMess: !data?.grosspremium
//             //   ? "Gross Total Premium is required"
//             //   : "",
//             addressMess: !data?.address ? "Address is required" : "",
//             // fileMess: !data?.file?.name ? "Document is required" : "",
//           });
//           return;
//         }
//       } else if (data?.policy_id == 1) {
//         if (
//           !data?.policy ||
//           !data?.policy_id ||
//           !data?.company ||
//           !data?.name ||
//           !data?.address ||
//           // !data?.fathername ||
//           !data?.dob ||
//           // validateData !== true ||
//           // !data?.mobile ||
//           // !data?.aadhar ||
//           !data?.district ||
//           !data?.description ||
//           !data?.pincode ||
//           (data?.pincode && data?.pincode?.length != 6)
//         ) {
//           setData({
//             ...data,
//             policyMess: !data?.policy ? "Policy is required" : "",
//             policyMess: !data?.policy_id
//               ? "Select Group Policy is required"
//               : "",
//             companyMess: !data?.company ? "Company is required" : "",
//             nameMess: !data?.name ? "Name is required" : "",
//             // fathernameMess: !data?.fathername
//             //   ? "Father / Husband Name is required"
//             //   : "",
//             addressMess: !data?.address ? "Address is required" : "",
//             dobMess: !data?.dob
//               ? "Date of birth is required"
//               : validateData !== true
//               ? `${validateData[0]}`
//               : "",

//             descriptionMess: !data?.description
//               ? "Description is required"
//               : "",

//             // mobileMess: !data?.mobile ? "Mobile is required" : "",
//             // aadharMess: !data?.aadhar ? "Aadhar is required" : "",
//             districtMess: !data?.district ? "District is required" : "",
//             pincodeMess: !data?.pincode
//               ? "Pincode is required"
//               : data?.pincode?.length != 6
//               ? "Pincode Must be 6 digit"
//               : "",
//           });
//           return;
//         }
//       } else if (data?.policy_id == 2) {
//         if (
//           !data?.policy ||
//           !data?.company ||
//           // !data?.docs?.name ||
//           !data?.name ||
//           !data?.vehiclenumber ||
//           // (data?.vehiclenumber && ) ||
//           !vehicalRegex.test(data?.vehiclenumber) ||
//           !data?.vehicletype ||
//           // !data?.registrationnumber ||
//           !data?.model ||
//           // !data?.chassisnumber ||
//           !data?.mfgyear ||
//           data?.mfgyear?.length != 4 ||
//           !data?.description

//           // !data?.email ||
//           // !data?.ida
//           // !data?.file?.name
//         ) {
//           setData({
//             ...data,
//             policyMess: !data?.policy ? "Policy is required" : "",
//             companyMess: !data?.company ? "Company is required" : "",
//             // docsMess: !data?.docs?.name ? "Image/Document is required" : "",
//             nameMess: !data?.name ? "Name is required" : "",
//             vehiclenumberMess: !data?.vehiclenumber
//               ? "Vehical Number is required"
//               : !vehicalRegex.test(data?.vehiclenumber) ||
//                 data?.vehiclenumber?.length <= 8
//               ? "Please Enter a Valid Vehicle Number"
//               : "",
//             vehicletypeMess: !data?.vehicletype
//               ? "Type of Vehicle is required"
//               : "",
//             // registrationnumberMess: !data?.registrationnumber
//             //   ? "Registration Number is required"
//             //   : "",
//             modelMess: !data?.model ? "Make/Model is required" : "",
//             // chassisnumberMess: !data?.chassisnumber
//             //   ? "Chassis Number is required"
//             //   : "",
//             mfgyearMess: !data?.mfgyear
//               ? "Mfg. year is required"
//               : data?.mfgyear?.length != 4 ||
//                 Number(data?.mfgyear) < Number(twentyFiveYearsAgo) ||
//                 Number(data?.mfgyear) > Number(Number(currentYear) + 1)
//               ? "Please Enter Valid Mfg. Year"
//               : "",
//             // emailMess: !data?.email
//             //   ? "Email is required"
//             //   : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.email)
//             //   ? "Please Enter a Vaild Email"
//             //   : "",
//             idaMess: !data?.ida ? "IDA is required" : "",
//             // fileMess: !data?.file?.name ? "Document is required" : "",
//             descriptionMess: !data?.description
//               ? "Description is required"
//               : "",
//           });
//           return;
//         }
//       } else if (data?.policy_id == 3) {
//         if (
//           !data?.policy ||
//           // !otherPolicy ||
//           !data?.company ||
//           !data?.name ||
//           !data?.description ||
//           !data?.file?.name
//         ) {
//           const obj = {
//             policyMess: !data?.policy ? "Policy is required" : "",
//             // otherPolicyMess: !otherPolicy ? "Other Policy is required" : "",
//             companyMess: !data?.company ? "Company is required" : "",
//             nameMess: !data?.name ? "Name is required" : "",
//             fileMess: !data?.file?.name ? "Document is required" : "",
//             descriptionMess: !data?.description
//               ? "Description is required"
//               : "",
//           };
//           if (
//             otherPolicy == "Life Insurance" &&
//             (!data?.address ||
//               !data?.dob ||
//               validateData !== true ||
//               // !data?.mobile ||
//               !data?.sonwife ||
//               !data?.lifecover ||
//               !data?.coveragetilldate)
//             // !data?.paymenttype
//           ) {
//             obj.addressMess = !data?.address ? "Address is required" : "";
//             obj.dobMess = !data?.dob
//               ? "Date of birth is required"
//               : validateData !== true
//               ? `${validateData[0]}`
//               : "";
//             // obj.mobileMess = !data?.mobile ? "Mobile is required" : "";
//             obj.sonwifeMess = !data?.sonwife ? "Son/Wife is required" : "";
//             obj.lifecoverMess = !data?.lifecover
//               ? "Life Cover is required"
//               : "";
//             obj.coveragetilldateMess = !data?.coveragetilldate
//               ? "Coverage Till Date is required"
//               : "";
//             // obj.paymenttypeMess = !data?.paymenttype
//             //   ? "Payment Type is required"
//             //   : "";
//           } else if (
//             otherPolicy == "Health Insurance" &&
//             (!data?.dob ||
//               // !data?.mobile ||
//               !data?.lifecover ||
//               !data?.coveragetilldate ||
//               // !data?.paymenttype ||
//               !data?.gender ||
//               // !data?.spouse ||
//               // !data?.daughter ||
//               // !data?.father ||
//               !data?.age)
//           ) {
//             obj.dobMess = !data?.dob
//               ? "Date of birth is required"
//               : validateData !== true
//               ? `${validateData[0]}`
//               : "";
//             // obj.mobileMess = !data?.mobile ? "Mobile is required" : "";
//             obj.sonwifeMess = !data?.sonwife ? "Son/Wife is required" : "";
//             obj.lifecoverMess = !data?.lifecover
//               ? "Life Cover is required"
//               : "";
//             obj.coveragetilldateMess = !data?.coveragetilldate
//               ? "Coverage Till Date is required"
//               : "";
//             // obj.paymenttypeMess = !data?.paymenttype
//             //   ? "Payment Type is required"
//             //   : "";
//             obj.genderMess = !data?.gender ? "Gender is required" : "";
//             // obj.spouseMess = !data?.spouse ? "Spouse is required" : "";
//             // obj.daughterMess = !data?.daughter ? "Daughter is required" : "";
//             // obj.fatherMess = !data?.father ? "Father is required" : "";
//             obj.ageMess = !data?.age ? "Age is required" : "";
//           }
//           setData({ ...data, ...obj });
//           return;
//         }
//       }
//       setLoading(true);
//       const body = new FormData();
//       if (data?.policy_id == 0) {
//         body.append("action", "distributor");
//         body.append("category_id", 8);
//         body.append("sub_category_id", data?.policy_id);
//         body.append("userid", user?.id);
//         body.append("name", data?.name);
//         body.append("address", data?.address);
//         body.append("location", data?.location);
//         body.append("insurance", data?.company);
//         body.append("pname", data?.p_name);
//         // body.append("email", data?.email);
//         body.append("description", data?.description);
//         // body.append("occurancy", data?.occupancy);
//         // body.append("fire_building", data?.firebuilding);
//         // body.append("fire_content", data?.firecontents);
//         // body.append("fire_stock", data?.firestocks);
//         // body.append("burglary", data?.burglary);
//         // body.append("eei", data?.eei);
//         // body.append("mbd", data?.mbd);
//         // body.append("gas_cylinder", data?.gascylinder);
//         // body.append("money_insurance", data?.moneyinsurance);
//         // body.append("money_transit", data?.moneyintransit);
//         // body.append("money_safe", data?.moneyinsafe);
//         // body.append("Fidelity_guarantee", data?.fidelity);
//         // body.append("tricycle", data?.tricycle);
//         // body.append("Public_liability", data?.liability);
//         // body.append("Compensation", data?.workmen);
//         // body.append("Plate_glass", data?.plateglass);
//         // body.append("Neon_sign", data?.neonsign);
//         // body.append("Personal_accident", data?.accident);
//         // body.append("Total_net", data?.premium);
//         // body.append("gst", data?.gst);
//         // body.append("Gross_total", data?.grosspremium);
//       } else if (data?.policy_id == 1) {
//         body.append("action", "employee");
//         body.append("category_id", 8);
//         body.append("sub_category_id", data?.policy_id);
//         body.append("userid", user?.id);
//         body.append("insurance", data?.company);
//         // body.append("policy", data?.group_policy);
//         body.append("name", data?.name);
//         body.append("address", data?.address);
//         // body.append("father_husband_name", data?.fathername);
//         body.append("dob", data?.dob);
//         // body.append("mobile_number", data?.mobile);
//         // body.append("adhar_no", data?.aadhar);
//         body.append("district", data?.district);
//         body.append("state", "Telangana");
//         body.append("pin", data?.pincode);
//         body.append("description", data?.description);
//         body.append("image", data?.file);
//       } else if (data?.policy_id == 2) {
//         body.append("action", "motor");
//         body.append("category_id", 8);
//         body.append("sub_category_id", data?.policy_id);
//         body.append("userid", user?.id);
//         // body.append("policy", data?.group_policy);
//         body.append("insurance", data?.company);
//         body.append("typ_vehicle", data?.vehicletype);
//         body.append("reg_num", data?.vehiclenumber);
//         body.append("model", data?.model);
//         // body.append("chasis", data?.chassisnumber);
//         body.append("mfg_year", data?.mfgyear);
//         body.append("name", data?.name);
//         // body.append("email_id", data?.email);
//         // body.append("ida", data?.ida);
//         // body.append("image", data?.docs);
//         body.append("description", data?.description);
//       } else if (data?.policy_id == 3) {
//         if (otherPolicy == "Life Insurance") {
//           body.append("action", "life");
//           body.append("category_id", 8);
//           body.append("sub_category_id", data?.policy_id);
//           body.append("userid", user?.id);
//           body.append("insurance", data?.company);
//           body.append("name", data?.name);
//           body.append("address", data?.address);
//           body.append("image", data?.file);
//           body.append("son_wife", data?.sonwife);
//           body.append("dob", data?.dob);
//           // body.append("mobile_number", data?.mobile);
//           body.append("life_cover", data?.lifecover);
//           body.append("coverage", data?.coveragetilldate);
//           // body.append("payment", data?.paymenttype);
//           body.append("description", data?.description);
//         } else if (otherPolicy == "Health Insurance") {
//           body.append("action", "health");
//           body.append("category_id", 8);
//           body.append("sub_category_id", data?.policy_id);
//           body.append("userid", user?.id);
//           body.append("insurance", data?.company);
//           body.append("name", data?.name);
//           body.append("gender", data?.gender);
//           // body.append("spouse", data?.spouse);
//           // body.append("daughter", data?.daughter);
//           // body.append("father", data?.father);
//           body.append("dob", data?.dob);
//           // body.append("mobile_number", data?.mobile);
//           body.append("life_cover", data?.lifecover);
//           body.append("coverage", data?.coveragetilldate);
//           // body.append("payment", data?.paymenttype);
//           body.append("description", data?.description);
//           body.append("age", data?.age);
//         }
//       }
//       const responce = await fetch(
//         process.env.REACT_APP_BASE_URL + "Insurance.php",
//         { method: "POST", body: body }
//       );
//       const res = await responce.json();
//       setLoading(false);
//       if (res.success) {
//         toast.success(res.message);
//         navigate(-1);
//         setData({});
//       } else {
//         toast.error(res.message);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading(false);
//     }
//   };

//   return (
//     <Container maxW={"container.xl"} py={5}>
//       <BreadCrumName
//         current={"Add Insurance"}
//         titlelist={[
//           { name: "Home", to: "/" },
//           { name: "Insurance", to: "/insurance" },
//         ]}
//       />

//       <Container maxW={"container.sm"} p={4}>
//         <SelectBox
//           req={true}
//           label={"Policy"}
//           options={
//             <>
//               <option value={"0"}>Distributor Package Policy</option>
//               <option value={"1"}>Employee Coverage Policy</option>
//               <option value={"2"}>Motor Insurance</option>
//               <option value={"3"}>Others</option>
//             </>
//           }
//           errorMess={data?.policyMess}
//           value={data?.policy_id}
//           onChange={(e) => {
//             setData({
//               ...data,
//               policy:
//                 e.target.value == 0
//                   ? "Distributor Package Policy"
//                   : e.target.value == 1
//                   ? "Employee Coverage Policy"
//                   : e.target.value == 2
//                   ? "Motor Insurance"
//                   : e.target.value == 3
//                   ? "Others"
//                   : "",
//               policy_id: e.target.value,
//               policyMess: "",
//             });
//             setOtherPolicy("");
//           }}
//         />
//         {/* {data?.policy == "Employee Coverage Policy" && (
//           <SelectBox
//             req={true}
//             errorMess={data?.group_policyMess}
//             value={data?.group_policy}
//             onChange={(e) =>
//               setData({
//                 ...data,
//                 group_policy: e.target.value,
//                 group_policyMess: "",
//               })
//             }
//             label={"Select Group Policy"}
//             placeholder={"Select"}
//             options={
//               <>
//                 <option value={"Delivery Package Policy"}>
//                   Delivery Package Policy
//                 </option>
//                 <option value={"Employee Coverage Policy"}>
//                   Employee Coverage Policy
//                 </option>
//                 <option value={"Motor Insurance"}>Motor Insurance</option>
//                 <option value={"Others"}>Others</option>
//               </>
//             }
//           />
//         )} */}
//         {data?.policy == "Others" && (
//           <SelectBox
//             label={"Others Policy"}
//             placeholder={"Select"}
//             options={other_policy_list?.map((v, i) => (
//               <option key={i} value={v.vehicle_name}>
//                 {v.vehicle_name}
//               </option>
//             ))}
//             value={otherPolicy}
//             onChange={(e) => setOtherPolicy(e.target.value)}
//             errorMess={data?.otherPolicyMess}
//           />
//         )}

//         <SelectBox
//           label={"Company List"}
//           placeholder={"Select"}
//           options={policy_company_list?.map((item, index) => (
//             <option key={index} value={item.provider}>
//               {item.provider}
//             </option>
//           ))}
//           value={data?.company}
//           onChange={(v) => {
//             setData({ ...data, company: v.target.value, companyMess: "" });
//           }}
//           errorMess={data?.companyMess}
//         />
//         {/* {data?.policy == "Employee Coverage Policy" ||
//           (data?.policy == "Motor Insurance" && (
//             <SelectImage
//               label={"Image/Document"}
//               value={data?.docs}
//               filename={data?.docs?.name}
//               onChange={(v) => {
//                 setData({ ...data, docs: v.target.files[0], docsMess: "" });
//               }}
//               errorMess={data?.docsMess}
//             />
//           ))} */}
//         <InputBox
//           req={true}
//           label={"Name"}
//           placeholder={"Name"}
//           value={data.name}
//           onChange={(v) => {
//             const input = v.target.value.replace(/\s\s+/g, " ");
//             setData({ ...data, name: input, nameMess: "" });
//           }}
//           errorMess={data.nameMess}
//         />
//         <>
//           {(otherPolicy == "Life Insurance" ||
//             data?.policy == "Employee Coverage Policy") && (
//             <InputBox
//               req={true}
//               label={"Address"}
//               placeholder={"Address"}
//               value={data.address}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   address: input,
//                   addressMess: "",
//                 });
//               }}
//               errorMess={data.addressMess}
//             />
//           )}
//           {(otherPolicy == "Life Insurance" ||
//             otherPolicy == "Health Insurance") && (
//             <InputBox
//               req={true}
//               max={eighteenYearsAgo2}
//               label={"Date of Birth"}
//               placeholder={"Date of Birth"}
//               type={"date"}
//               value={data.dob}
//               onChange={(v) => {
//                 setData({
//                   ...data,
//                   dob: v.target.value,
//                   dobMess: "",
//                 });
//               }}
//               errorMess={data.dobMess}
//             />
//           )}
//           {/* {(otherPolicy == "Life Insurance" ||
//             otherPolicy == "Health Insurance") && (
//             <InputBox
//               label={"Mobile Number"}
//               placeholder={"Mobile Number"}
//               value={data.mobile}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 10) {
//                   setData({
//                     ...data,
//                     mobile: input,
//                     mobileMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.mobileMess}
//             />
//           )} */}
//           {otherPolicy == "Life Insurance" && (
//             <InputBox
//               label={"Son/Wife"}
//               placeholder={"Son/Wife"}
//               value={data.sonwife}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   sonwife: input,
//                   sonwifeMess: "",
//                 });
//               }}
//               errorMess={data.sonwifeMess}
//             />
//           )}
//           {(otherPolicy == "Life Insurance" ||
//             otherPolicy == "Health Insurance") && (
//             <InputBox
//               label={"Life Cover"}
//               placeholder={"Life Cover"}
//               value={data.lifecover}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   lifecover: input,
//                   lifecoverMess: "",
//                 });
//               }}
//               errorMess={data.lifecoverMess}
//             />
//           )}
//           {(otherPolicy == "Life Insurance" ||
//             otherPolicy == "Health Insurance") && (
//             <InputBox
//               label={"Coverage Till Date"}
//               placeholder={"Coverage Till Date"}
//               type={"Date"}
//               value={data.coveragetilldate}
//               onChange={(v) => {
//                 setData({
//                   ...data,
//                   coveragetilldate: v.target.value,
//                   coveragetilldateMess: "",
//                 });
//               }}
//               errorMess={data.coveragetilldateMess}
//             />
//           )}
//           {/* {(otherPolicy == "Life Insurance" ||
//             otherPolicy == "Health Insurance") && (
//             <InputBox
//               label={"Payment Type"}
//               placeholder={"Payment Type"}
//               value={data.paymenttype}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   paymenttype: input,
//                   paymenttypeMess: "",
//                 });
//               }}
//               errorMess={data.paymenttypeMess}
//             />
//           )} */}
//           {otherPolicy == "Health Insurance" && (
//             <GridInputComponent
//               p={[0, 0]}
//               mt={5}
//               content={
//                 <RadioGroup
//                   value={data.gender}
//                   onChange={(e) => {
//                     setData({ ...data, gender: e, genderMess: "" });
//                   }}
//                   errorMess={data?.genderMess}
//                 >
//                   <Stack spacing={5} direction="row">
//                     <Flex color={"brand.primary"}> Gender</Flex>
//                     <Radio colorScheme="purple" value="1">
//                       Male
//                     </Radio>
//                     <Radio colorScheme="purple" value="2">
//                       Female
//                     </Radio>
//                   </Stack>
//                   {data?.genderMess && (
//                     <Text mt={2} color={"#f00"} fontSize={14}>
//                       {data?.genderMess}
//                     </Text>
//                   )}
//                 </RadioGroup>
//               }
//             />
//           )}
//           {/* {otherPolicy == "Health Insurance" && (
//             <InputBox
//               label={"Spouse"}
//               placeholder={"Spouse"}
//               value={data.spouse}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   spouse: input,
//                   spouseMess: "",
//                 });
//               }}
//               errorMess={data.spouseMess}
//             />
//           )} */}
//           {/* {otherPolicy == "Health Insurance" && (
//             <InputBox
//               label={"Daughter"}
//               placeholder={"Daughter"}
//               value={data.daughter}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   daughter: input,
//                   daughterMess: "",
//                 });
//               }}
//               errorMess={data.daughterMess}
//             />
//           )} */}
//           {/* {otherPolicy == "Health Insurance" && (
//             <InputBox
//               label={"Father"}
//               placeholder={"Father"}
//               value={data.father}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   father: input,
//                   fatherMess: "",
//                 });
//               }}
//               errorMess={data.fatherMess}
//             />
//           )} */}
//           {otherPolicy == "Health Insurance" && (
//             <InputBox
//               label={"Your Age"}
//               placeholder={"Your Age"}
//               value={data.age}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 2) {
//                   setData({
//                     ...data,
//                     age: input,
//                     ageMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.ageMess}
//             />
//           )}
//         </>
//         <>
//           {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Vehicle Number"}
//               placeholder={"Vehicle Number"}
//               value={data.vehiclenumber}
//               onChange={(v) => {
//                 const input1 = v.target.value?.toUpperCase();
//                 if (vehicalRegex.test(input1) || input1 === "") {
//                   setData({
//                     ...data,
//                     vehiclenumber: input1,
//                     vehiclenumberMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.vehiclenumberMess}
//             />
//           )}
//           {data?.policy == "Motor Insurance" && (
//             <SelectBox
//               value={data.vehicletype}
//               onChange={(v) => {
//                 setData({
//                   ...data,
//                   vehicletype: v.target.value,
//                   vehicletypeMess: "",
//                 });
//               }}
//               errorMess={data.vehicletypeMess}
//               label={"Type of Vehicle"}
//               // placeholder={"Select"}
//               options={
//                 <>
//                   <option hidden="" value="">
//                     SELECT
//                   </option>
//                   {TypeOfVehicle?.map((val, ind) => (
//                     <option key={ind} value={val?.value}>
//                       {val?.lable}
//                     </option>
//                   ))}

//                   {/* <option value="1">THREE WHEELER</option>
//                   <option value="2">FOUR WHEELER</option>
//                   <option value="3">TRUCK</option>
//                   <option value="4">OTHERS</option> */}
//                 </>
//               }
//             />
//           )}
//           {/* {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Registration Number"}
//               placeholder={"Registration Number"}
//               value={data.registrationnumber}
//               onChange={(v) => {
//                 const input = v.target.value?.toUpperCase();
//                 if (alfaNumericWithoutSpace.test(input) || input === "") {
//                   setData({
//                     ...data,
//                     registrationnumber: input,
//                     registrationnumberMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.registrationnumberMess}
//             />
//           )} */}
//           {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Make/Model"}
//               placeholder={"Make/Model"}
//               value={data.model}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   model: input,
//                   modelMess: "",
//                 });
//               }}
//               errorMess={data.modelMess}
//             />
//           )}
//           {/* {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Chassis Number"}
//               placeholder={"Chassis Number"}
//               value={data.chassisnumber}
//               onChange={(v) => {
//                 const input = v.target.value?.toUpperCase();
//                 if (alfaNumericWithoutSpace.test(input) || input === "") {
//                   setData({
//                     ...data,
//                     chassisnumber: input,
//                     chassisnumberMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.chassisnumberMess}
//             />
//           )} */}
//           {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Mfg. year"}
//               // type={"year"}
//               placeholder={"Mfg. year"}
//               value={data.mfgyear}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 4) {
//                   setData({
//                     ...data,
//                     mfgyear: input,
//                     mfgyearMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.mfgyearMess}
//             />
//           )}
//           {/* {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"Email ID"}
//               placeholder={"Email ID"}
//               value={data.email}
//               onChange={(v) => {
//                 const input = v.target.value.trim();
//                 setData({ ...data, email: input, emailMess: "" });
//               }}
//               errorMess={data.emailMess}
//             />
//           )} */}
//           {/* {data?.policy == "Motor Insurance" && (
//             <InputBox
//               label={"I D A"}
//               placeholder={"I D A"}
//               value={data.ida}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   ida: input,
//                   idaMess: "",
//                 });
//               }}
//               errorMess={data.idaMess}
//             />
//           )} */}
//         </>
//         <>
//           {/* {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               label={"Father/Husband Name"}
//               placeholder={"Father/Husband Name"}
//               value={data.fathername}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   fathername: input,
//                   fathernameMess: "",
//                 });
//               }}
//               errorMess={data.fathernameMess}
//             />
//           )} */}
//           {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               req={true}
//               max={eighteenYearsAgo2}
//               label={"Date of Birth"}
//               placeholder={"Date of Birth"}
//               type={"date"}
//               value={data.dob}
//               onChange={(v) => {
//                 setData({ ...data, dob: v.target.value, dobMess: "" });
//               }}
//               errorMess={data.dobMess}
//             />
//           )}
//           {/* {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               label={"Mobile Number"}
//               placeholder={"Mobile Number"}
//               value={data.mobile}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 10) {
//                   setData({
//                     ...data,
//                     mobile: input,
//                     mobileMess: "",
//                   });
//                 }
//               }}
//               errorMess={data.mobileMess}
//             />
//           )} */}
//           {/* {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               label={"Aadhaar Number"}
//               placeholder={"Aadhaar Number"}
//               value={data.aadhar}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 12) {
//                   setData({ ...data, aadhar: input, aadharMess: "" });
//                 }
//               }}
//               errorMess={data.aadharMess}
//             />
//           )} */}
//           {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               req={true}
//               label={"District"}
//               placeholder={"District"}
//               value={data.district}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\s\s+/g, " ");
//                 setData({
//                   ...data,
//                   district: input,
//                   districtMess: "",
//                 });
//               }}
//               errorMess={data.districtMess}
//             />
//           )}
//           {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               label={"State"}
//               placeholder={"State"}
//               value={"Telangana"}
//               // onChange={(v) => {
//               //   setData({ ...data, district: v.target.value, districtMess: "" });
//               // }}
//               // errorMess={data.districtMess}
//             />
//           )}
//           {data?.policy == "Employee Coverage Policy" && (
//             <InputBox
//               req={true}
//               label={"Pincode"}
//               placeholder={"Pincode"}
//               value={data.pincode}
//               onChange={(v) => {
//                 const input = v.target.value.replace(/\D/g, "");
//                 if (input.length <= 6) {
//                   setData({ ...data, pincode: input, pincodeMess: "" });
//                 }
//               }}
//               errorMess={data.pincodeMess}
//             />
//           )}
//         </>
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             req={true}
//             label={"Address"}
//             placeholder={"Address"}
//             value={data.address}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({ ...data, address: input, addressMess: "" });
//             }}
//             errorMess={data.addressMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             req={true}
//             label={"Location"}
//             placeholder={"Location"}
//             value={data.location}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({ ...data, location: input, locationMess: "" });
//             }}
//             errorMess={data.locationMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             req={true}
//             label={"Proprietory Name"}
//             placeholder={"Proprietory Name"}
//             value={data.p_name}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({ ...data, p_name: input, p_nameMess: "" });
//             }}
//             errorMess={data.p_nameMess}
//           />
//         )}
//         {/* {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Email ID"}
//             placeholder={"Email ID"}
//             value={data.email}
//             onChange={(v) => {
//               const input = v.target.value.trim();
//               setData({ ...data, email: input, emailMess: "" });
//             }}
//             errorMess={data.emailMess}
//           />
//         )} */}
//         {/* {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Description"}
//             placeholder={"Description"}
//             value={data.description}
//             area={true}
//             req={true}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 description: input,
//                 descriptionMess: "",
//               });
//             }}
//             errorMess={data.descriptionMess}
//           />
//         )} */}
//         {/* {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Occupancy"}
//             placeholder={"Occupancy"}
//             value={data.occupancy}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 occupancy: input,
//                 occupancyMess: "",
//               });
//             }}
//             errorMess={data.occupancyMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Fire-Building"}
//             placeholder={"Fire-Building"}
//             value={data.firebuilding}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 firebuilding: input,
//                 firebuildingMess: "",
//               });
//             }}
//             errorMess={data.firebuildingMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Fire Contents Other than Stocks"}
//             placeholder={"Fire Contents Other than Stocks"}
//             value={data.firecontents}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 firecontents: input,
//                 firecontentsMess: "",
//               });
//             }}
//             errorMess={data.firecontentsMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Fire-Stocks"}
//             placeholder={"Fire-Stocks"}
//             value={data.firestocks}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 firestocks: input,
//                 firestocksMess: "",
//               });
//             }}
//             errorMess={data.firestocksMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Burglary"}
//             placeholder={"Burglary"}
//             value={data.burglary}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 burglary: input,
//                 burglaryMess: "",
//               });
//             }}
//             errorMess={data.burglaryMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"EEI"}
//             placeholder={"EEI"}
//             value={data.eei}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 eei: input,
//                 eeiMess: "",
//               });
//             }}
//             errorMess={data.eeiMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"MBD"}
//             placeholder={"MBD"}
//             value={data.mbd}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 mbd: input,
//                 mbdMess: "",
//               });
//             }}
//             errorMess={data.mbdMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Gas Cylinders In Transit"}
//             placeholder={"Gas Cylinders In Transit"}
//             value={data.gascylinder}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 gascylinder: input,
//                 gascylinderMess: "",
//               });
//             }}
//             errorMess={data.gascylinderMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Money Insurance"}
//             placeholder={"Money Insurance"}
//             value={data.moneyinsurance}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 moneyinsurance: input,
//                 moneyinsuranceMess: "",
//               });
//             }}
//             errorMess={data.moneyinsuranceMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Money in Transit"}
//             placeholder={"Money in Transit"}
//             value={data.moneyintransit}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 moneyintransit: input,
//                 moneyintransitMess: "",
//               });
//             }}
//             errorMess={data.moneyintransitMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Money in Safe/Till"}
//             placeholder={"Money in Safe/Till"}
//             value={data.moneyinsafe}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 moneyinsafe: input,
//                 moneyinsafeMess: "",
//               });
//             }}
//             errorMess={data.moneyinsafeMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Fidelity Guarantee"}
//             placeholder={"Fidelity Guarantee"}
//             value={data.fidelity}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 fidelity: input,
//                 fidelityMess: "",
//               });
//             }}
//             errorMess={data.fidelityMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Tricycles"}
//             placeholder={"Tricycles"}
//             value={data.tricycle}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 tricycle: input,
//                 tricycleMess: "",
//               });
//             }}
//             errorMess={data.tricycleMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Public Liability Insurance"}
//             placeholder={"Public Liability Insurance"}
//             value={data.liability}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 liability: input,
//                 liabilityMess: "",
//               });
//             }}
//             errorMess={data.liabilityMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Workmen Compensation"}
//             placeholder={"Workmen Compensation"}
//             value={data.workmen}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 workmen: input,
//                 workmenMess: "",
//               });
//             }}
//             errorMess={data.workmenMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Plate Glass"}
//             placeholder={"Plate Glass"}
//             value={data.plateglass}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 plateglass: input,
//                 plateglassMess: "",
//               });
//             }}
//             errorMess={data.plateglassMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Neon Sign Board"}
//             placeholder={"Neon Sign Board"}
//             value={data.neonsign}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 neonsign: input,
//                 neonsignMess: "",
//               });
//             }}
//             errorMess={data.neonsignMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Personal Accident"}
//             placeholder={"Personal Accident"}
//             value={data.accident}
//             onChange={(v) => {
//               const input = v.target.value.replace(/\s\s+/g, " ");
//               setData({
//                 ...data,
//                 accident: input,
//                 accidentMess: "",
//               });
//             }}
//             errorMess={data.accidentMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Total Net Premium"}
//             placeholder={"Total Net Premium"}
//             value={data.premium}
//             onChange={(v) => {
//               // const input = v.target.value.replace(/\s\s+/g, " ");
//               const inputValue = v.target.value;
//               if (
//                 (inputValue?.length <= 15 &&
//                   /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
//                 inputValue == 0 ||
//                 !data.premium
//               ) {
//                 setData({
//                   ...data,
//                   premium: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)
//                     ? inputValue
//                     : "",
//                   premiumMess: "",
//                 });
//               }
//             }}
//             errorMess={data.premiumMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Add: GST @18%"}
//             placeholder={"Add: GST @18%"}
//             value={data.gst}
//             onChange={(v) => {
//               const inputValue = v.target.value;
//               if (
//                 (inputValue?.length <= 15 &&
//                   /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
//                 inputValue == 0 ||
//                 !data.premium
//               ) {
//                 setData({
//                   ...data,
//                   gst: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)
//                     ? inputValue
//                     : "",
//                   gstMess: "",
//                 });
//               }
//             }}
//             errorMess={data.gstMess}
//           />
//         )}
//         {data?.policy == "Distributor Package Policy" && (
//           <InputBox
//             label={"Gross Total Premium"}
//             placeholder={"Gross Total Premium"}
//             value={data.grosspremium}
//             onChange={(v) => {
//               const inputValue = v.target.value;
//               if (
//                 (inputValue?.length <= 15 &&
//                   /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(inputValue)) ||
//                 inputValue == 0 ||
//                 !data.premium
//               ) {
//                 setData({
//                   ...data,
//                   grosspremium: /^(0|[1-9]\d{0,11})(\.\d{0,2})?$/.test(
//                     inputValue
//                   )
//                     ? inputValue
//                     : "",
//                   grosspremiumMess: "",
//                 });
//               }
//             }}
//             errorMess={data.grosspremiumMess}
//           />
//         )} */}
//         {(data?.policy == "Employee Coverage Policy" ||
//           data?.policy == "Others") && (
//           <SelectImage
//             label={"Upload Document"}
//             value={data.file?.name}
//             filename={data.file?.name}
//             onChange={(v) => {
//               setData({ ...data, file: v.target.files[0], fileMess: "" });
//             }}
//             errorMess={data.fileMess}
//           />
//         )}
//         <InputBox
//           label={"Description"}
//           placeholder={"Description"}
//           value={data.description}
//           area={true}
//           req={true}
//           onChange={(v) => {
//             const input = v.target.value.replace(/\s\s+/g, " ");
//             setData({
//               ...data,
//               description: input,
//               descriptionMess: "",
//             });
//           }}
//           errorMess={data.descriptionMess}
//         />
//         <Center mt={6}>
//           <CustomButton
//             title={"Submit"}
//             onClick={() => onAddInsurance()}
//             loading={loading}
//           />
//         </Center>
//       </Container>
//     </Container>
//   );
// };

// export default AddInsurance;
