import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { FaHeart, FaIndianRupeeSign } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import img from "../assets/Supliers/OurOfferingIcon.svg";
import { Image, Text, Tooltip } from "@chakra-ui/react";
import { toast } from "react-toastify";

export const CartCard = ({
  remove_cart,
  update_quantity,
  qty,
  Price,
  prod_name,
  product_cat,
  color,
  url,
  min_qty,
}) => {
  return (
    <div class="item">
      <Tooltip
        hasArrow
        label="Remove from the cart"
        bg="gray.300"
        color="black"
      >
        <div class="buttons">
          <RxCross2 size={20} cursor={"pointer"} onClick={remove_cart} />
        </div>
      </Tooltip>

      <div class="image">
        <Image
          cursor={"pointer"}
          src={url}
          h={["auto", "auto", "auto", "auto"]}
          w={["auto", "auto", "auto", "220px"]}
          objectFit={"fill"}
          borderRadius={10}
        />
      </div>

      <div class="description">
        <Text fontSize={14} color={"#2B3478"}>
          {product_cat}
        </Text>
        <Text fontSize={25} color={"#000"} fontWeight={"bold"}>
          {prod_name}
        </Text>
        <Text fontSize={14} color={"#2B3478"}>
          {color}
        </Text>
      </div>

      <div class="quantity">
        <button class="plus-btn" type="button" name="button">
          <CiSquareMinus
            onClick={() => {
              const count = qty - 1;
              if (min_qty > count) {
                toast.error("Minimum Quantity Required");
              } else {
                update_quantity(qty - 1);
              }
            }}
            size={35}
          />
        </button>
        <input
          type="text"
          style={{ fontSize: "24px" }}
          name="name"
          value={qty}
        />
        <button class="minus-btn" type="button" name="button">
          <CiSquarePlus
            onClick={() => {
              update_quantity(qty + 1);
            }}
            size={35}
          />
        </button>
      </div>

      <div class="total-price">
        <FaIndianRupeeSign />
        {Number(Price * qty || 0).toFixed(2)}
      </div>
    </div>
  );
};
