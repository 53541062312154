import {
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import { HeadingMain } from "../../../../../components/Compnent";
import imgProfile from "../../../../../assets/Supliers/Profile_user.svg";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";

const AdviceDetails = () => {
  const [list, setlist] = useState([1, 2, 2, 3]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state, itemData } = location?.state;
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Order Details"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: -4 },
          { name: "Get Advice", to: -3 },
          { name: state?.name, to: -2 },
          { name: `${state?.name} Orders`, to: -1 },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        <Flex gap={5} alignItems={"flex-start"} flexDirection={"column"}>
          <HeadingMain title={itemData?.Agency_name} />
          <Text>{itemData?.title}</Text>
          <Flex flexDirection={"column"} gap={"full"}>
            <Flex>{itemData?.description}</Flex>
            <Flex>
             {itemData?.status == "1" ?
              
              <Grid
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
              ]}
              gap={10}
            >
              {loading ? (
                <Loader />
              ) : list.length == 0 ? (
                <Center p={10}>
                  <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
                    No Data Found
                  </Text>
                </Center>
              ) : (
                list.map((v, i) => (
                  <GridItem
                    key={i}
                    w="100%"
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                    p={5}
                    cursor={"pointer"}
                  >
                    <Image
                      src={imgProfile}
                      h={"100%"}
                      w={"100%"}
                      objectFit={"fill"}
                      p={5}
                      alignSelf={"center"}
                    />
                    <Flex
                      mt={2}
                      w={["full", "30%"]}
                      alignItems={"flex-end"}
                      flexDirection={["row", "row"]}
                      justifyContent={"flex-end"}
                      gap={"2"}
                    >
                      <CustomButton
                        w="full"
                        title={"View"}
                        bg={"brand.primary"}
                        //   onClick={() => onOpen()}
                      />
                    </Flex>
                  </GridItem>
                ))
              )}
            </Grid>:""}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
};

export default AdviceDetails;
