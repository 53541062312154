import {
  Box,
  Center,
  Container,
  Flex,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";
import { ListDataShow, Table1 } from "../../../../../components/Compnent";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomPopup from "../../../../../components/CustomPopup";
import StatusComp from "../../../../../components/StatusComp";
import { FeedBackbar } from "../../../../../components/FeedBack";
import { BorderFunction } from "../../../../../utils/utils";
import moment from "moment";

const AdviceOrder = () => {
  const [list, setlist] = useState([]);
  const [showTable, setShowTable] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [id, setId] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      onClose();
      setLoading(true);
      const body = new FormData();
      body.append("action", "notificationlist");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id);
      body.append("service_provider", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setlist(res?.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  const status_update = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      if (updateType == "Complete") {
        body.append("action", "supplier_complete");
        body.append("supplier_id", user?.id);
        body.append("id", id);
        body.append("complete_status", 1);
      } else {
        body.append("action", "updatenotification");
        body.append(
          "status",
          updateType == "Accept" ? "1" : updateType == "Reject" ? "2" : ""
        );
        body.append("id", id);
      }
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        fetchData();
        onClose();
        toast.success(res?.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Notifications"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: -3 },
          { name: "Get Advice", to: -2 },
          { name: state?.name, to: -1 },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading ? (
          <Loader />
        ) : list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          list?.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              border={BorderFunction(v?.status)}
              gap={[5, "5%"]}
              position={"relative"}
            >
              <StatusComp status={v?.status} />
              <Flex flexDirection={"column"} w={"100%"}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Name"}
                  value={v?.Agency_name}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={v?.Description}
                />
                {v?.status == "1" && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Email"}
                      value={v?.Email_Address}
                    />
                  </>
                )}
                {v?.feedback_status > 0 && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Rating"}
                      value={<FeedBackbar rating={v?.feedback_status} />}
                    />
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      title={"Feed-Back"}
                      value={v?.feedback}
                    />
                  </>
                )}
              </Flex>
              <Flex
                mt={2}
                w={["full"]}
                alignItems={"center"}
                flexDirection={["row", "row"]}
                justifyContent={"center"}
                gap={"3"}
              >
                <Flex display={"flex"} justifyContent={"center"} gap={"3"}>
                  {v?.status == "0" ? (
                    <>
                      <CustomButton
                        w="full"
                        title={"Accept"}
                        bg={"brand.primary"}
                        onClick={() => {
                          setId(v?.ID);
                          setUpdateType("Accept");
                          onOpen();
                        }}
                      />
                      <CustomButton
                        w="full"
                        title={"Reject"}
                        bg={"brand.btn"}
                        onClick={() => {
                          setId(v?.ID);
                          setUpdateType("Reject");
                          onOpen();
                        }}
                      />
                    </>
                  ) : v?.status == "1" &&
                    v?.payment_status == "1" &&
                    v?.complete_status == 0 ? (
                    <CustomButton
                      w="full"
                      title={"Complete"}
                      bg={"brand.primary"}
                      onClick={() => {
                        setId(v?.ID);
                        setUpdateType("Complete");
                        onOpen();
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {v?.status == "1" 
                  
                  &&
                  <CustomButton
                  w="full"
                  title={"View/Download"}
                  bg={"black"}
                  onClick={() =>
                    navigate("/get-advice/order-details", {
                      state: {
                        state: state,
                        itemData: v,
                      },
                    })
                  }
                />

                  }

                 
                </Flex>
              </Flex>
              <Flex
                w={"full"}
                direction={"flex"}
                justifyContent={["flex-start", "flex-end"]}
                alignItems={["flex-start", "flex-end"]}
                flexDirection={["column", "row"]}
                gap={[2, 0]}
              >
                <Text
                  // w={"fit-content"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  w={"fit-content"}
                  textAlign={"right"}
                  flex={1}
                  color={"brand.primary"}
                  gap={4}
                  onClick={() => {
                    if (showTable == i + 1) {
                      setShowTable("");
                    } else {
                      setShowTable(i + 1);
                    }
                  }}
                >
                  {showTable == i + 1 ? "Less Status" : "Show Status"}
                </Text>
              </Flex>
              {showTable == i + 1 && (
                <Table1
                  headData={[
                    "Sl No.",
                    "Provider Name",
                    "Status",
                    "Status Update Date",
                  ]}
                >
                  {v?.status_details?.map((val, ind) => (
                    <Tr key={i}>
                      <Td>
                        <Center>{ind + 1}</Center>
                      </Td>
                      <Td>
                        <Center>{val?.provider}</Center>
                      </Td>
                      <Td>
                        <Center>{val?.title}</Center>
                      </Td>
                      <Td>
                        <Center>
                          {moment(val?.datetime).format("DD-MMM-YYYY hh:mm A")}
                        </Center>
                      </Td>
                    </Tr>
                  ))}
                </Table1>
              )}
            </Box>
          ))
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={status_update}
        b_name={updateType}
        onClose={onClose}
        title={updateType}
        mess={`Are you sure? You want to ${updateType} this Service.`}
      />
    </Container>
  );
};

export default AdviceOrder;
