import { Box, Center, Container, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { ListDataShow } from "../../../../../components/Compnent";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";
import { useSelector } from "react-redux";
import { get_insu_sup_sent_list } from "../../../../../redux/dataSlice";
import store from "../../../../../redux/store";
import { TypeOfVehicle } from "../../../../../utils/utils";
import { downloadFile } from "../../../../../utils/Hook/DownloadFile";
import { toast } from "react-toastify";

const ViewInsSup = () => {
  const [list, setlist] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const obj = {
    userid: user?.id,
  };

  const { insu_sup_sent_list, insu_sup_sent_loading } = useSelector(
    (state) => state.allData
  );


  useEffect(() => {
    store.dispatch(get_insu_sup_sent_list(obj));
  }, []);

  const download_file = async (id, type) => {
    try {
      setLoading1(true);
      const body = new FormData();
      body.append("action", "downloadInsurance");
      body.append("type", type);
      body.append("id", id);

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Insurance.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();

      setLoading1(false);
      if (res.success) {
        // toast.success(res.message);
        await downloadFile(res?.file, res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Sent Quotations"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: "/home" },
          { name: "Insurance", to: -1 },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading || insu_sup_sent_loading ? (
          <Loader />
        ) : insu_sup_sent_list?.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          insu_sup_sent_list?.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"100%"} gap={0}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Type of Request / Insurance"}
                  value={v?.Insurance_Type}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Company"}
                  value={v?.company}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Name"}
                  value={v?.Name}
                />
                {v?.PName && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Proprietory Name"}
                    value={v?.PName}
                  />
                )}
                {v?.Address && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Address"}
                    value={v?.Address}
                  />
                )}
                {v?.Location && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Location"}
                    value={v?.Location}
                  />
                )}
                {v?.DOB && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Date of Birth"}
                    value={v?.DOB}
                  />
                )}
                {v?.Reg_num && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Vehicle Number"}
                    value={v?.Reg_num}
                  />
                )}

                {v?.Type_vehicle && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Type of Vehicle"}
                    value={
                      TypeOfVehicle?.filter(
                        (val, ind) => val?.value == v?.Type_vehicle
                      )?.[0]?.lable
                    }
                  />
                )}

                {v?.Model && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Make/Model"}
                    value={v?.Model}
                  />
                )}

                {v?.Mfg_year && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Mfg. year"}
                    value={v?.Mfg_year}
                  />
                )}
                {v?.son_wife_name && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Son/Wife"}
                    value={v?.son_wife_name}
                  />
                )}

                {(v?.Life_Cover || v?.Life_cover) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Life Cover"}
                    value={v?.Life_Cover || v?.Life_cover}
                  />
                )}

                {(v?.Coverage_Till || v?.Coverage) && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Coverage Till Date"}
                    value={v?.Coverage_Till || v?.Coverage}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {v?.Age && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Your Age"}
                    value={v?.Age}
                  />
                )}

                {v?.Gender && (
                  <ListDataShow
                    f1={[4, 6]}
                    f2={[7, 14]}
                    title={"Gender"}
                    value={v?.Gender}
                  />
                )}

                {/* <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Age"}
                  value={"25"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"State"}
                  value={"Madhya Pradesh"}
                /> */}
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={v?.Description}
                />
              </Flex>
              <Flex
                mt={2}
                w={["full", "30%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"2"}
              >
                {v?.Document && (
                  <CustomButton
                    w="full"
                    title={"Download Recieved"}
                    bg={"brand.btn"}
                    //   onClick={() => onOpen()}
                    onClick={() => {
                      //
                      downloadFile(v?.Document);
                      // download_file(v?.id,v?.Insurance_Type)
                    }}
                  />
                )}
                <CustomButton
                  loading={loading1}
                  w="full"
                  title={"Download Sent"}
                  bg={"brand.primary"}
                  //   onClick={() => onOpen()}
                  onClick={() => {
                    //
                    // downloadFile(v?.Document);
                    download_file(v?.id, v?.Insurance_Type);
                  }}
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>
    </Container>
  );
};

export default ViewInsSup;
