import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { ListDataShow } from "../../../../components/Compnent";
import { FaCartPlus } from "react-icons/fa6";
import store from "../../../../redux/store";
import {
  get_add_to_cart,
  get_consumable_product_list,
  get_member_cart_list,
  get_order_details,
  remove_from_cart,
  update_cart,
} from "../../../../redux/dataSlice";
import useDebounce from "../../../../utils/Hook/DebounceHook";

const ProductDetails = () => {
  const [mainImage, setMainImage] = useState(0);
  // const [count, setCount] = useState(0);
  const { state } = useLocation();
  const { type } = useSelector((state) => state.otherData);
  const [list, setList] = useState([]);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const {
    order_details,
    order_loading,
    consumable_product_list,
    mem_cart_list,
  } = useSelector((state) => state.allData);
  const obj = { sub_cat_id: state?.id, userid: user?.id };

  console.log("order_details ====", order_details);
  console.log("list ====", list);

  useEffect(() => {
    setList(mem_cart_list);
  }, [mem_cart_list]);

  const navigate = useNavigate();

  useEffect(() => {
    if (state?.pid) {
      store.dispatch(get_order_details(state.pid));
      store.dispatch(get_consumable_product_list(state?.id));
      store.dispatch(get_member_cart_list(obj));
    }
  }, [state]);

  const add_to_cart = async (id, qty) => {
    store.dispatch(
      get_add_to_cart({
        id,
        qty,
        navigationFunc: navigate("/let's-shop/cart", { state: state }),
        userId: user?.id,
      })
    );
  };

  const remove_item = async (id) => {
    store.dispatch(remove_from_cart({ id, userId: user?.id, obj }));
  };

  const debouncedUpdate = useDebounce((id, qty) => {
    store.dispatch(
      update_cart({
        id,
        qty,
        obj,
        userId: user?.id,
      })
    );
  }, 800);

  const setCount = (id, qty) => {
    setList((prevList) =>
      prevList.map((item) =>
        item.product_id === id ? { ...item, quantity: qty } : item
      )
    );
    debouncedUpdate(list?.filter((v, i) => v?.product_id == id)?.[0]?.ID, qty);
  };

  return (
    <Container maxW={"container.xl"} py={[0, 5]}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={"Product Details"}
          titlelist={[
            { name: "Home", to: "/" },
            type == 1 && { name: "Our Offering", to: -3 },
            { name: type == 1 ? "Consumables" : "Let's Shop", to: -2 },
            { name: state?.name, to: -1 },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          {type == "0" && (
            <CustomButton
              onClick={() => navigate("/let's-shop/cart", { state: state })}
              rightIcon={<FaCartPlus size={20} color="#fff" />}
              title={"Cart"}
            />
          )}
        </Flex>
      </Flex>
      <Container maxW={["container", "container.xl"]} p={0} m={0} mt={[2, 10]}>
        {order_loading ? (
          <Loader />
        ) : (
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent={["center", "flex-start"]}
            alignItems={["center", "flex-start"]}
          >
            <Flex
              flex={1}
              flexDirection={["column-reverse", "column-reverse", "row"]}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={5}
            >
              <Flex
                // w={["80px"]}
                overflowX={["scroll", "hidden"]}
                flexDirection={["row", "row", "column"]}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                gap={3}
              >
                {order_details?.product_image?.map((item, i) => (
                  <>
                    {mainImage != i && (
                      <Image
                        onClick={() => setMainImage(i)}
                        key={i}
                        src={item?.image_url}
                        cursor={"pointer"}
                        h={["40px", "80px"]}
                        w={["40px", "80px"]}
                        objectFit={"fill"}
                        borderRadius={10}
                        overflow={"hidden"}
                        border={"none"}
                      />
                    )}
                  </>
                ))}
              </Flex>
              <Flex>
                <Image
                  src={order_details?.product_image?.[mainImage]?.image_url}
                  cursor={"pointer"}
                  h={[400]}
                  w={["auto"]}
                  objectFit={"fill"}
                  borderRadius={10}
                  overflow={"hidden"}
                  border={"none"}
                />
              </Flex>
            </Flex>
            <Flex
              flex={1}
              flexDirection={"column"}
              gap={2}
              mt={[6, 0]}
              ml={[0, 10]}
            >
              <Text fontSize={14} color={"#2B3478"}>
                {
                  consumable_product_list?.filter(
                    (v) => v?.id == order_details?.product_cat
                  )?.[0]?.name
                }
              </Text>
              <Text fontSize={25} color={"#000"} fontWeight={"bold"}>
                {order_details?.P_name}
              </Text>
              <Flex gap={2}>
                <Text fontSize={18} color={"#AEAEAE"}>
                  <del style={{ color: "#AEAEAE" }}>
                    Rs.{" "}
                    {(
                      Number(order_details?.Price) +
                      Number(order_details?.Discount)
                    ).toFixed(2)}
                    {/* Rs. 12,300.00 */}
                  </del>
                </Text>
                <Text fontSize={18} color={"#000"}>
                  <b>
                    Rs. {Number(order_details?.Price).toFixed(2)}
                    {/* Rs. 12,000.00 */}
                  </b>
                </Text>
              </Flex>
              <div style={{ display: "flex", alignItems: "center" }}>
                Color :{" "}
                <span
                  style={{
                    height: "20px ",
                    width: "20px ",
                    borderRadius: "50%",
                    marginTop: "3px",
                    marginLeft: "10px",
                    backgroundColor: order_details?.Color,
                  }}
                ></span>
              </div>
              <Text
                fontSize={16}
                color={"#D1262B"}
                pb={1}
                p={1}
                maxW={"fit-content"}
                borderBottom={"1px solid #D1262B"}
                cursor={"pointer"}
              >
                Size Guide
              </Text>
              <Text fontSize={16} color={"#000"}>
                Size : <b>{order_details?.Size}</b>
              </Text>
              <Text fontSize={14} color={"#000"}>
                Min Order Qty : <b>{order_details?.min_quantity} pcs</b>
              </Text>
              {type == "0" && (
                <Flex mt={2}>
                  {list?.find((v, i) => v?.product_id == order_details?.ID)
                    ?.quantity > 0 ? (
                    <>
                      <CustomButton
                        hover_req={false}
                        w={200}
                        h={35}
                        bg={"#f8f9fe"}
                        t_color={"#000"}
                        rightIcon={
                          <CiSquarePlus
                            onClick={() => {
                              setCount(
                                order_details?.ID,
                                list?.find(
                                  (v, i) => v?.product_id == order_details?.ID
                                )?.quantity + 1
                              );
                            }}
                            style={{ marginRight: "10px" }}
                            size={32}
                          />
                        }
                        icon={
                          <CiSquareMinus
                            onClick={() => {
                              const qty =
                                list?.find(
                                  (v, i) => v?.product_id == order_details?.ID
                                )?.quantity - 1;
                              if (qty < order_details?.min_quantity) {
                                remove_item(list?.find(
                                  (v, i) => v?.product_id == order_details?.ID
                                )?.ID);
                              } else {
                                setCount(
                                  order_details?.ID,
                                  list?.find(
                                    (v, i) => v?.product_id == order_details?.ID
                                  )?.quantity - 1
                                );
                              }
                            }}
                            style={{ marginLeft: "10px" }}
                            size={32}
                          />
                        }
                        title={
                          <span style={{ fontSize: "20px" }}>
                            {
                              list?.find(
                                (v, i) => v?.product_id == order_details?.ID
                              )?.quantity
                            }
                          </span>
                        }
                      />
                    </>
                  ) : (
                    <CustomButton
                      w={200}
                      h={35}
                      // onClick={() => setCount(1)}
                      onClick={() => {
                        add_to_cart(
                          order_details?.ID,
                          order_details?.min_quantity
                        );
                      }}
                      hover_req={false}
                      title={"Add to Cart"}
                      bg={"brand.primary"}
                      // bg={count == 0 ? "brand.primary" : "#fff"}
                    />
                  )}
                </Flex>
              )}

              <Flex gap={5} mt={6} fontSize={20}>
                <Text
                  borderBottom={"2px solid #000"}
                  p={1}
                  fontWeight={"bold"}
                  pb={2}
                  cursor={"pointer"}
                >
                  Product Details
                </Text>
                <Text
                  borderBottom={"2px solid #000"}
                  p={1}
                  fontWeight={"bold"}
                  pb={2}
                  cursor={"pointer"}
                >
                  Size & Fit
                </Text>
              </Flex>
              <Flex w={"full"} flexDirection={"column"}>
                {/*
                <ListDataShow
                  f1={[8, 8]}
                  f2={[20, 20]}
                  title={"Product Description"}
                  value={
                   order_details?.Description
                  }
                  titleColor={"#000"}
                />
                <ListDataShow
                  f1={[8, 8]}
                  f2={[20, 20]}
                  title={"Material & Care : "}
                  value={"Cotton, No Machine Wash."}
                  titleColor={"#000"}
                  color={"000"}
                />
                */}
                <Accordion>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Product Description
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {order_details?.Description}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Container>
    </Container>
  );
};

export default ProductDetails;
