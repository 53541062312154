import { Text } from '@chakra-ui/react'
import React from 'react'

export const NoData = () => {
  return (
    <Text p={1} color={"brand.primary"} fontSize={25} textAlign={"center"} w={"full"}>
        Data Not Found
    </Text>
  )
}
