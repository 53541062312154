import { Container, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import {
  ProductDetailsCard,
  TotalPrice,
} from "../../../../../components/ProductCard";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import store from "../../../../../redux/store";
import { get_consumable_product_list } from "../../../../../redux/dataSlice";
import { CustomButton } from "../../../../../utils/LayoutUtils";
import { IoCheckmarkCircle } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { ReduceFunction } from "../../../../../utils/utils";

const ShopOrderDetails = () => {
  const location = useLocation();
  const { state, itemData } = location.state;
  const { consumable_product_list } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_consumable_product_list(state?.id));
  }, []);
  console.log("state ===", state);
  console.log("itemData?.products ===", itemData);
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Order Details"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: -4 },
          { name: "Consumables", to: -3 },
          { name: state.name, to: -2 },
          { name: "Orders", to: -1 },
        ]}
      />
      <Container maxW="64rem" mt={[5, 5]} p={[1, 2, 3, 4]}>
        <Flex
          flexDirection={"column"}
          gap={5}
          paddingTop={10}
          paddingBottom={10}
          paddingLeft={3}
          paddingRight={3}
          border={`1px solid ${
            itemData?.status == "0"
              ? "white"
              : itemData?.status == "1"
              ? "green"
              : itemData?.status == "2"
              ? "red"
              : ""
          }`}
          position={"relative"}
        >
          {itemData?.status != "0" ? (
            <Text position={"absolute"} right={"50%"} top={0}>
              {itemData?.status == "1" ? (
                <IoCheckmarkCircle size={35} color={"green"} />
              ) : itemData?.status == "2" ? (
                <RxCrossCircled size={35} color={"red"} />
              ) : (
                ""
              )}
            </Text>
          ) : (
            ""
          )}
          <Flex w={"full"} justifyContent={"space-between"} fontWeight={"bold"}>
            <Text>
              {itemData?.order_id} :{" "}
              {moment(itemData?.datetime).format("DD-MMM-YYYY hh:mm A")}
            </Text>
            {itemData?.status == "1" && (
              <CustomButton
                w="auto"
                title={"Complete Order"}
                bg={"brand.primary"}
              />
            )}
          </Flex>
          {itemData?.products?.length > 0 &&
            itemData?.products?.map((item, i) => (
              <>
                <ProductDetailsCard
                  key={i}
                  onImageClick={() => {
                    navigate("/let's-shop/details", {
                      state: { ...state, pid: item?.ID },
                    });
                  }}
                  image={item?.P_image}
                  itemType={
                    consumable_product_list?.filter(
                      (v) => v?.id == item?.product_cat
                    )[0]?.name
                  }
                  name={item?.P_name}
                  brand={item?.Brand}
                  status={itemData?.status}
                  quntity={item?.quantity}
                  discount={item?.Discount}
                  min_qty={item?.min_quantity}
                  total_price={Number(item?.quantity) * Number(item?.Price)}
                  totalDiscount={
                    Number(item?.quantity) * Number(item?.Discount)
                  }
                  totalAmount={`${
                    Number(item?.quantity) * Number(item?.Price) -
                    Number(item?.quantity) * Number(item?.Discount)
                  }`}
                />
              </>
            ))}

          <Flex
            w={"full"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={10}
            p={3}
          >
            <Text fontWeight={"bold"} fontSize={18}>
              Grand total
            </Text>
            <Text w={"150px"}>
              <TotalPrice
                amt={Number(
                  ReduceFunction(itemData?.products, "total")
                ).toFixed(2)}
              />
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
};

export default ShopOrderDetails;
