import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { images } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputBox } from "../../utils/LayoutUtils";
import { IoArrowBackOutline } from "react-icons/io5";
import { onLogin } from "../../utils/apis";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import store from "../../redux/store";
import {
  get_district_list,
  get_service_list,
  get_user_type,
} from "../../redux/otherSlice";

const Login = () => {
  const otpRef = useRef(null);

  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(null);

  useEffect(() => {
    if (data.show_otp) {
      document.querySelector('input[data-index="0"]').focus();
    }
  }, [data.show_otp]);

  const onCheckNumber = () => {
    if (!data.number) {
      setData({
        ...data,
        numMess: !data.number ? "Number is required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile_Number", data.number);
    onLogin(body, setLoading)
      .then((res) => {
        console.log("res ===", res);
        if (res.success) {
          if (res.data == 0 || res.data == 1) {
            // setTimeout(() => otpRef.current.focus(), 0);
            setData({
              ...data,
              show_other_reg: false,
              show_otp: true,
              otp_sent_mob: res?.mobile_number,
            });
            setShowPass(res.data);
            toast.success(res.message);
            localStorage.setItem("type", res.data);
          } else if (res.data == 2) {
            navigate("/register", { state: data.number });
          }
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  const onClickLogin = () => {
    if (
      !data?.number ||
      !data?.pass ||
      (data?.pass && data?.pass?.length != 6)
    ) {
      setData({
        ...data,
        numMess: !data?.number ? "Number is required." : "",
        passMess: !data?.pass
          ? "OTP is required."
          : data?.pass?.length !== 6
          ? "OTP must be six digits."
          : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile_Number", data?.number);
    body.append("otp", data?.pass);
    onLogin(body, setLoading)
      .then((res) => {
        console.log("res ===", res);
        if (res.success) {
          toast.success(res.msg);
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("mobile", data?.number);
          localStorage.setItem("services", res.service);
          store.dispatch(get_user_type());
          navigate("/profile");
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    store.dispatch(get_district_list());
    store.dispatch(get_service_list());
  }, []);
  return (
    <Container
      // alignItems={["center", "center", "center", "center"]}
      // justifyContent={["flex-start", "flex-start", "flex-start", "flex-start"]}
      //  // minH={"100vh"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      minW="100vw"
      h={"100%"}
      // bg={"brand.white"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Container maxW={"container.lg"} pt={5}>
        <Flex alignSelf={"flex-start"} alignItems={"center"} gap={5}>
          <IoArrowBackOutline
            size={30}
            color="#2b3478"
            cursor={"pointer"}
            onClick={() => navigate(-1)}
          />
          <Text fontSize={[18, 22, 26, 30]} fontWeight={600} color={"#2b3478"}>
            Login
          </Text>
        </Flex>
      </Container>
      <Stack p={5} maxW="lg" w={"100%"} mt={["10px", 10, 20, 70]}>
        <Image
          src={images.logo}
          h={"150px"}
          w={"150px"}
          objectFit={"contain"}
          alignSelf={"center"}
        />
        <Heading
          fontSize={[16, 18, 20]}
          textAlign={"center"}
          py={2}
          fontWeight={600}
        >
          LPG Association
        </Heading>
        <Text fontSize={18} fontWeight={400} color={"brand.black"}>
          Login
        </Text>
        <InputBox
          placeholder={"Mobile Number"}
          mt={2}
          value={data.number}
          disable={data?.show_otp}
          maxLength={10}
          onChange={(v) => {
            setData({
              ...data,
              number: v.target.value.trim().replace(/[^0-9 ]/g, ""),
              numMess: "",
            });
          }}
          errorMess={data.numMess}
        />
        {/* {(showPass == 0 || showPass == 1) && (
          <InputBox
            placeholder={"Password"}
            value={data.pass}
            onChange={(v) => {
              setData({ ...data, pass: v.target.value.trim(), passMess: "" });
            }}
            errorMess={data.passMess}
            type={show ? "text" : "password"}
            RightElement={
              show ? (
                <FiEyeOff size={20} color="#000" onClick={handleClick} />
              ) : (
                <FiEye size={20} color="#000" onClick={handleClick} />
              )
            }
          />
        )} */}

        {(showPass == 0 || showPass == 1) && (
          <Stack display={"flex"} dir="col" justify={"center"} align={"center"}>
            <Text textAlign={"center"} mt={3}>
              We have sent an OTP to your registered mobile number ending in{" "}
              {data?.otp_sent_mob?.split(8, 10)}.
            </Text>
            <HStack mt={3}>
              <PinInput
                ref={otpRef}
                size="lg"
                placeholder="-"
                onChange={(v) => {
                  setData({ ...data, pass: v, passMess: "" });
                }}
              >
                {" "}
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
                <PinInputField
                  border={"1px solid #000"}
                  fontSize="16Spx"
                  w="45px"
                  h="45px"
                />
              </PinInput>
            </HStack>
            {data?.passMess && (
              <Text color={"#f00"} fontSize={14} mt={1}>
                {data?.passMess}
              </Text>
            )}
          </Stack>
        )}
        {/* <Text
          fontSize={16}
          fontWeight={400}
          color={"brand.text"}
          alignSelf={"end"}
          cursor={"pointer"}
          mt={2}
          onClick={() => navigate("/reset-password")}
        >
          Forgot Password?
        </Text> */}
        <CustomButton
          title={"Login"}
          mt={5}
          onClick={
            showPass == 0 || showPass == 1 ? onClickLogin : onCheckNumber
          }
          loading={loading}
        />
        {/* <Text
          fontSize={16}
          fontWeight={400}
          color={"brand.text"}
          alignSelf={"center"}
          cursor={"pointer"}
          mt={2}
          onClick={() => navigate("/register")}
        >
          Create Account?
        </Text> */}
      </Stack>
    </Container>
  );
};

export default Login;
