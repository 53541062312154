import {
  Center,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { images } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputBox } from "../utils/LayoutUtils";
import {
  FaTwitterSquare,
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaYoutube,
} from "react-icons/fa";
import CustomModal from "./CustomModal";
import { GoMail } from "react-icons/go";
import { MailLink } from "./Compnent";
import { contact_us_api } from "../utils/apis";
import { toast } from "react-toastify";
import { FaXTwitter } from "react-icons/fa6";
import { CiYoutube } from "react-icons/ci";


const Footer = ({ Ref, refund, privacy }) => {
  const [objData, setObjData] = useState({});
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose,
  } = useDisclosure();

  const mailtoLink = `mailto:${"naveen@veramasa.com"}?cc=${"naveen05patidar@gmail.com"}&subject=${encodeURIComponent(
    "Test Purpose"
  )}&body=${encodeURIComponent("Test Purpose")}`;

  const handleClick = () => {
    window.location.href = mailtoLink;
  };
  const validate = () => {
    const obj = {
      nameError: "",
      name_orgError: "",
      emailError: "",
      mobileError: "",
      specsError: "",
    };

    if (!objData?.name || objData?.name.trim().length === 0) {
      obj.nameError = "Please Enter Name";
    } else if (/\s{2,}/.test(objData.name)) {
      obj.nameError = "Only one space allowed between words";
    }

    if (!objData?.name_org || objData?.name_org.trim().length === 0) {
      obj.name_orgError = "Please Enter Organization Name";
    } else if (/\s{2,}/.test(objData.name_org)) {
      obj.name_orgError = "Only one space allowed between words";
    }
    if (!objData?.email || objData.email.trim().length === 0) {
      obj.emailError = "Please Enter Email";
    } else if (!/^\S+@\S+\.\S+$/.test(objData.email)) {
      obj.emailError = "Please Enter a Valid Email Address";
    }
    if (!objData?.mobile || objData.mobile.trim().length === 0) {
      obj.mobileError = "Please Enter Mobile";
    } else if (!/^\d{10}$/.test(objData.mobile)) {
      obj.mobileError = "Please Enter a Valid 10-digit Mobile Number";
    }
    if (!objData?.specs || objData?.specs.trim().length === 0) {
      obj.specsError = "Please Enter Specifications";
    }

    setObjData({ ...objData, ...obj });
  };
  const contact_us = async () => {
    const data = validate();
    if (
      objData?.nameError?.length == 0 ||
      objData?.name_orgError?.length == 0 ||
      objData?.emailError?.length == 0 ||
      objData?.mobileError?.length == 0 ||
      objData?.specsError?.length == 0
    ) {
      try {
        const body = new FormData();
        body.append("Name", objData?.name?.trim());
        body.append("Name_org", objData?.name_org?.trim());
        body.append("Email", objData?.email);
        body.append("Mobile_Number", objData?.mobile);
        body.append("Specification", objData?.specs?.trim());
        const responce = await contact_us_api(body);
        if (responce?.success) {
          toast.success(responce.message);
          setObjData({
            name: "",
            name_org: "",
            email: "",
            mobile: "",
            specs: "",
          });
        } else if (!responce?.success) {
          toast.success(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Flex
      w="100%"
      alignSelf={"center"}
      alignItems={"center"}
      gap={10}
      justifyContent={"space-between"}
      bg={"#2b3478"}
      p={10}
      flexDirection={["column", "column", "column", "row"]}
      ref={Ref}
    >
      <Flex gap={3} flexDirection={["column", "column", "column", "row"]}>
        <Image
          src={images.logo}
          h={["50px", "60px", "210"]}
          w={["50px", "60px", "200px"]}
          objectFit={"contain"}
          cursor={"pointer"}
          borderRadius={6}
          bg={"brand.white"}
          p={1}
          onClick={() => navigate("/")}
        />
        <Stack spacing={0}>
          <Text color={"#fff"} fontSize={20} p={0}>
            LPG Association
          </Text>
          {/* <Text color={"#fff"} fontSize={20} p={0}>
            xxxxxx3166
          </Text> */}
          <Link color={"#fff"} fontSize={20} p={0} onClick={handleClick}>
            lpgassociation@gmail.com
            {/* <MailLink
              title={"lpgassociation@gmail.com"}
              senderEmail={"naveen05patidar@gmail.com"}
              recipientEmail={"lpgassociation@gmail.com"}
              subject={"test"}
              body={"test"}
            /> */}
          </Link>
          <Flex gap={3} mt={2}>
            <FaXTwitter
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
            {/* <FaFacebookSquare
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            /> */}
            <FaYoutube
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />

            <FaLinkedin
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
            <FaInstagramSquare
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
          </Flex>
          <Flex gap={5} mt={5}>
            <Text
              color={"#fff"}
              fontSize={20}
              p={0}
              cursor={"pointer"}
              onClick={onOpen}
              _hover={{
                fontWeight: "bold",
                transition: "",
              }}
            >
              Privacy Policy
            </Text>
            <Text
              color={"#fff"}
              fontSize={20}
              p={0}
              cursor={"pointer"}
              onClick={onPopOpen}
              _hover={{
                fontWeight: "bold",
                transition: "all .5s ease-in",
              }}
            >
              Refund Policy
            </Text>
          </Flex>
          <Flex
            gap={5}
            mt={5}
            flexDirection={["column", "column", "column", "row"]}
            _hover={{
              border: "1px solid brand.btn",
            }}
          >
            <Image
              src={images.googleplay}
              h={"50px"}
              w={"auto"}
              objectFit={"contain"}
              cursor={"pointer"}
            />
            <Image
              src={images.appstore}
              h={"50px"}
              w={"auto"}
              cursor={"pointer"}
              objectFit={"contain"}
            />
          </Flex>
        </Stack>
      </Flex>
      <Stack spacing={0} maxW={"500px"} w={"100%"} p={0}>
        <Text
          textAlign={"center"}
          color={"#fff"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Contact Us
        </Text>
        <InputBox
          value={objData?.name}
          onChange={(e) => {
            setObjData({ ...objData, name: e.target.value, nameError: "" });
          }}
          errorMess={objData?.nameError}
          placeholder={"Name"}
        />
        <InputBox
          value={objData?.name_org}
          onChange={(e) => {
            setObjData({
              ...objData,
              name_org: e.target.value,
              name_orgError: "",
            });
          }}
          errorMess={objData?.name_orgError}
          placeholder={"Name of the Organization"}
        />
        <InputBox
          value={objData?.email}
          onChange={(e) => {
            setObjData({ ...objData, email: e.target.value, emailError: "" });
          }}
          errorMess={objData?.emailError}
          placeholder={"Email"}
        />
        <InputBox
          value={objData?.mobile}
          onChange={(e) => {
            const input = e.target.value.replace(/[^0-9]/g, "");
            if (input?.length <= 10) {
              setObjData({
                ...objData,
                mobile: input,
                mobileError: "",
              });
            }
          }}
          errorMess={objData?.mobileError}
          placeholder={"Phone Number"}
        />
        <InputBox
          value={objData?.specs}
          onChange={(e) => {
            setObjData({ ...objData, specs: e.target.value, specsError: "" });
          }}
          errorMess={objData?.specsError}
          placeholder={"Specifications"}
        />
        <Center mt={3}>
          <CustomButton
            bg={"brand.btn"}
            title={"Submit"}
            onClick={contact_us}
          />
        </Center>
      </Stack>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={" TLDA Privacy Policy"}
      >
        {privacy && <div dangerouslySetInnerHTML={{ __html: privacy }} />}
       
      </CustomModal>
      <CustomModal
        isOpen={isPopOpen}
        onClose={onPopClose}
        title={"TLDA Refund Policy"}
      >
       
        {refund && <div dangerouslySetInnerHTML={{ __html: refund }} />}

      
      </CustomModal>
    </Flex>
  );
};

export default Footer;
