import { Container, Flex, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import img from "../assets/Supliers/letsShopImg.svg";
import { CustomButton } from "../utils/LayoutUtils";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { motion } from "framer-motion";
import { FaHeart } from "react-icons/fa6";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const MotionCiSquareMinus = motion(CiSquareMinus);
const MotionCiSquarePlus = motion(CiSquarePlus);

export const ProductCard = ({
  min_order,
  Discount,
  product_cat,
  onClick,
  EditClick,
  DeleteClick,
  url,
  price,
  qunatity,
  prod_name,
  subCat,
  add = false,
  count,
  setCount,
  heart,
  setHeart,
  type,
  nav,
  AddToCart,
  addLoading,
  remove_item,
  product_images = [],
}) => {
  console.log("min_order ===", min_order);

  console.log("count ===", count);

  return (
    <GridItem
      maxW={400}
      w="100%"
      h={"100%"}
      bg="#fff"
      shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      borderRadius={10}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      display={"flex"}
      flexDirection={"column"}
      height={480}
      overflow={"hidden"}
      gap={3}
      position={"relative"}
      // onClick={nav}
    >
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          bottom: 30,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        loop={true}
      >
        {product_images.map((item, i) => (
          <SwiperSlide onClick={nav} key={i}>
            <Image
              cursor={"pointer"}
              src={item?.image_url}
              h={["280px", "280px", "280px"]}
              w={["100%", "100%", "300px"]}
              objectFit={"fill"}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Flex
        position={"absolute"}
        bgColor={"#fff"}
        zIndex={2}
        height={213}
        borderRadius={10}
        px={4}
        py={2}
        top={267}
        w={"100%"}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"}
        justifyContent={"space-evenly"}
      >
        <Flex flexDirection={"column"} cursor={"pointer"} onClick={nav}>
          <Text fontSize={14} color={"#2B3478"}>
            {product_cat}
          </Text>
          <Text fontSize={25} color={"#000"} fontWeight={"bold"}>
            {prod_name}
          </Text>
          <Flex gap={2}>
            <Text fontSize={14} color={"#AEAEAE"}>
              <del style={{ color: "#AEAEAE" }}>
                Rs. {(Number(price) + Number(Discount)).toFixed(2)}
              </del>
            </Text>
            <Text fontSize={14} color={"#000"}>
              <b> Rs. {Number(price).toFixed(2)}</b>
            </Text>
          </Flex>
          <Text fontSize={14} color={"#000"}>
            Min Order Qty : <b>{min_order} pcs</b>
          </Text>
        </Flex>
        {type == "supplier" ? (
          <Flex gap={2} w={"full"} justifyContent={"space-evenly"}>
            <CustomButton
              title={"Edit"}
              bg={"#fff"}
              t_color={"brand.primary"}
              border_color={"brand.primary"}
              new_btn={true}
              w={"100px"}
              onClick={EditClick}
            />
            <CustomButton
              title={"Delete"}
              bg={"#fff"}
              t_color={"brand.btn"}
              border_color={"brand.btn"}
              new_btn={true}
              w={"100px"}
              onClick={DeleteClick}
            />
          </Flex>
        ) : (
          <>
            {add && (
              <>
                {count == "0" ? (
                  <CustomButton
                    h={35}
                    onClick={AddToCart}
                    hover_req={false}
                    w={"full"}
                    title={"Add to Cart"}
                    bg={"brand.btn"}
                    loading={addLoading}
                  />
                ) : (
                  <CustomButton
                    hover_req={false}
                    w={"full"}
                    h={35}
                    bg={"brand.primary"}
                    rightIcon={
                      <CiSquarePlus
                        onClick={() => {
                          setCount(count + 1);
                        }}
                        style={{ marginRight: "10px" }}
                        size={32}
                      />
                    }
                    icon={
                      <CiSquareMinus
                        onClick={() => {
                          if (min_order < count) {
                            setCount(count - 1);
                          } else {
                            remove_item();
                          }
                        }}
                        style={{ marginLeft: "10px" }}
                        size={32}
                      />
                    }
                    title={count}
                  />
                )}
              </>
            )}
          </>
        )}

        {/* <Flex
          pl={3}
          w={"100%"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Text fontSize={13}>{product_cat}</Text>
          <Text fontSize={13}>{subCat}</Text>
          <Text fontSize={13}>Rs. {Discount} Discount</Text>
          <Text fontSize={13}>Min Order Qty = </Text>
          {EditIcon && (
            <Text
              pl={4}
              color={"brand.primary"}
              fontSize={22}
              mt={3}
              textAlign={"center"}
            >
              {EditIcon}
            </Text>
          )}
        </Flex> */}
        {/* <Flex
          pr={4}
          w={"100%"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          flexDirection={"column"}
        >
          <Text fontSize={13}>{prod_name}</Text>
          <Text fontSize={13}>Rs. {Number(price).toFixed(2)}</Text>
          <Text fontSize={13}>Qty: {qunatity}</Text>
          <Text fontSize={13}>{min_order} pieces</Text>
          {DeleteIcon && (
            <Text
              pr={4}
              color={"brand.btn"}
              fontSize={25}
              mt={3}
              textAlign={"center"}
            >
              {DeleteIcon}
            </Text>
          )}
        </Flex> */}
      </Flex>
    </GridItem>
  );
};

export const ProductDetailsCard = ({
  itemType,
  quntity,
  discount,
  min_qty,
  total_price,
  totalDiscount,
  totalAmount,
  image,
  name,
  brand,
  onImageClick,
}) => {
  return (
    <Flex flexDirection={["column", "row"]}>
      <Flex h={["120px", "250px"]} w={["200px", "500px"]} position={"relative"}>
        <Image
          src={image}
          onClick={onImageClick}
          h={["120px", "250px"]}
          w={["200px", "250px"]}
          objectFit={"fill"}
          cursor={"pointer"}
          borderRadius={"10px"}
        />
      </Flex>
      <Flex
        p={3}
        gap={2}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Text fontSize={13}>{itemType}</Text>
        <Text fontSize={13}>{name}</Text>
        <Text fontSize={13}>{brand}</Text>
        <Text fontSize={13}>Qty: {quntity}</Text>
        <Text fontSize={13}> Discount Rs. {discount}</Text>
        <Text fontSize={13}>Min Order Qty = {min_qty} pieces</Text>
        <Text fontSize={13}></Text>
      </Flex>
      <Flex
        p={3}
        gap={2}
        w={"250px"}
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
        flexDirection={"column"}
      >
        <Text fontSize={13}>MRP : {total_price}</Text>
        <Text fontSize={13}>Discount :{totalDiscount}</Text>

        <TotalPrice amt={totalAmount} />
      </Flex>
    </Flex>
  );
};

export const TotalPrice = ({ amt }) => {
  return (
    <Text
      borderTop={"4px"}
      borderBottom={"4px"}
      width={"100%"}
      p={2}
      textAlign={"right"}
      fontWeight={"bold"}
      fontSize={18}
    >
      {amt}
    </Text>
  );
};
